//LOGIN
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const REGISTER = "REGISTER";
export const REFRESHTOKEN = "REFRESHTOKEN";
export const VERIFY_EMAIL = "VERIFY_EMAIL";
export const COMPLETE_REGISTRATION = "COMPLETE_REGISTRATION";
export const RESEND_VERIFICATION_EMAIL = "RESEND_VERIFICATION_EMAIL";

//USER
export const EDIT_USER_DETAILS = "EDIT_USER_DETAILS";
export const EDIT_USER_SETTINGS = "EDIT_USER_SETTINGS";
export const EDIT_USER_PROFILE = "EDIT_USER_PROFILE";
export const CHANGE_USER_PASSWORD = "CHANGE_USER_PASSWORD";
export const UPLOAD_USER_ID = "UPLOAD_USER_ID";

//Account
export const LOAD_ACCOUNT = "LOAD_ACCOUNT";
export const EDIT_ACCOUNT_SETTINGS = "EDIT_ACCOUNT_SETTINGS";

//PASSWORD RESET
export const SEND_PASSWORD_RESET_LINK = "SEND_PASSWORD_RESET_LINK";
export const VERIFY_RESET_PASSWORD_LINK = "VERIFY_RESET_PASSWORD_LINK";
export const RESET_PASSWORD = "RESET_PASSWORD";

//ORGANIZATION
export const LOAD_ORGANIZATION = "LOAD_ORGANIZATION";

//CURRENCIES
export const LOAD_CURRENCIES = "LOAD_CURRENCIES";

//ALERT
export const ADD_ALERT = "ADD_ALERT";

//LOADING
export const TOGGLE_LOADING = "TOGGLE_LOADING";

//PROJECTS
export const LOAD_ALL_PROJECT = "LOAD_ALL_PROJECT";
export const SAVE_PROJECT = "SAVE_PROJECT";
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const UPGRADE_SUBSCRIPTION = "UPGRADE_SUBSCRIPTION";
export const START_PROJECT = "START_PROJECT";
export const STOP_PROJECT = "STOP_PROJECT";
export const TERMINATE_PROJECT = "TERMINATE_PROJECT";
export const LOAD_PROJECT_BY_ID = "LOAD_PROJECT_BY_ID";
export const LOAD_PROJECT_COST = "LOAD_PROJECT_COST";
export const LOAD_ALL_CREDENTIAL = "LOAD_ALL_CREDENTIAL";
export const SAVE_CREDENTIAL = "SAVE_CREDENTIAL";
export const UPDATE_CREDENTIAL = "UPDATE_CREDENTIAL";
export const SEND_UPGRADE_SUBSCRIPTION_REQUEST =
  "SEND_UPGRADE_SUBSCRIPTION_REQUEST";
export const LOAD_PROJECT_COUNTS = "LOAD_PROJECT_COUNTS";
export const GENERATE_CONVERSATION_HISTORY = "GENERATE_CONVERSATION_HISTORY";

//GAIP to RECOMMENDER
export const GET_RANK_SETTINGS = "GET_RANK_SETTINGS";
export const UPDATE_RANK_SETTINGS = "UPDATE_RANK_SETTINGS";
export const GET_MAPPER = "GET_MAPPER";
export const CREATE_MAPPER = "CREATE_MAPPER";
export const UPDATE_MAPPER = "UPDATE_MAPPER";
export const GET_INDICES = "GET_INDICES";
export const CREATE_INDICES = "CREATE_INDICES";
export const CREATE_RANK = "CREATE_RANK";
export const UPLOAD_RECOMMENDER_DATA = "UPLOAD_RECOMMENDER_DATA";
export const ITEMS_TRAIN = "ITEMS_TRAIN";
export const IMAGES_TRAIN = "IMAGES_TRAIN";
export const RANKS_TRAIN = "RANKS_TRAIN";
export const GET_TASKS_STATUS = "GET_TASKS_STATUS";
export const DB_STORE_TASKS_STATUS = "DB_STORE_TASKS_STATUS";
export const DB_GET_TASKS_STATUS = "DB_GET_TASKS_STATUS";
export const LOAD_TRAIN_TASKS = "LOAD_TRAIN_TASKS";
export const GET_PREVIOUSLY_SETUP_STATE = "GET_PREVIOUSLY_SETUP_STATE";
export const GET_CATALOG_ITEMS = "GET_CATALOG_ITEMS";
export const REMOVE_CATALOG_ITEMS = "REMOVE_CATALOG_ITEMS";
export const UPDATE_CATALOG_ITEM = "UPDATE_CATALOG_ITEM";

//GPT Profile
export const GET_GPT_PROFILES = "GET_GPT_PROFILES";
export const GET_GPT_PROFILE_BY_ID = "GET_GPT_PROFILE_BY_ID";
export const CREATE_GPT_PROFILE = "CREATE_GPT_PROFILE";
export const UPDATE_GPT_PROFILE_STATE = "UPDATE_GPT_PROFILE_STATE";
export const UPDATE_GPT_PROFILE_API = "UPDATE_GPT_PROFILE_API";
export const DELETE_GPT_PROFILE = "DELETE_GPT_PROFILE";

//GPT Settings
export const CREATE_GPT_SETTINGS = "CREATE_GPT_SETTINGS";
export const GET_GPT_SETTINGS = "GET_GPT_SETTINGS";
export const DELETE_GPT_SETTINGS = "DELETE_GPT_SETTINGS";
export const UPDATE_GPT_SETTINGS = "UPDATE_GPT_SETTINGS";

//GPT Datasets
export const GET_GPT_DATASETS = "GET_GPT_DATASETS";
export const GET_GPT_DATASETS_SUMMARY = "GET_GPT_DATASETS_SUMMARY";
export const GET_GPT_DATASET_BY_ID = "GET_GPT_DATASET_BY_ID";
export const CREATE_GPT_DATASET = "CREATE_GPT_DATASET";
export const UPDATE_GPT_DATASET_API = "UPDATE_GPT_DATASET_API";
export const UPDATE_GPT_DATASET_FILE = "UPDATE_GPT_DATASET_FILE";
export const UPDATE_GPT_DATASET_STATE = "UPDATE_GPT_DATASET_STATE";
export const TERMINATE_GPT_DATASET = "TERMINATE_GPT_DATASET";

export const UPDATE_GPT_SETUP_STATE = "UPDATE_GPT_SETUP_STATE";

//GPT Tasks
export const GET_ALL_TASKS = "GET_ALL_TASKS";
export const GET_TASK_STATUS = "GET_TASK_STATUS";
export const GET_TASKS_BY_PREFIX = "GET_TASKS_BY_PREFIX";

//GPT Documents
export const GET_GPT_DOCUMENTS = "GET_GPT_DOCUMENTS";
export const GET_GPT_DOCUMENT_BY_ID = "GET_GPT_DOCUMENT_BY_ID";
export const CREATE_GPT_DOCUMENT = "CREATE_GPT_DOCUMENT";
export const UPDATE_GPT_DOCUMENT = "UPDATE_GPT_DOCUMENT";
export const TERMINATE_GPT_DOCUMENTS = "TERMINATE_GPT_DOCUMENTS";

//Train
export const TRAIN_GPT_DATASET = "TRAIN_GPT_DATASET";

export const RECOMMENDER_INSIGHTS = "RECOMMENDER_INSIGHTS";
export const RECOMMENDER_UPDATE_FEEDBACK = "RECOMMENDER_UPDATE_FEEDBACK";

//DATASET
export const LOAD_ALL_DATASETS = "LOAD_ALL_DATASETS";
export const CREATE_DATASET = "CREATE_DATASET";
export const UPLOAD_DATASET = "UPLOAD_DATASET";
export const ADD_CONVERSATION_TO_DATASET = "ADD_CONVERSATION_TO_DATASET";
export const DATASET_DOWNLOAD_REQUEST = "DATASET_DOWNLOAD_REQUEST";

//GPT Conversations
export const RETRIEVE_GPT_CONVERSATIONS = "RETRIEVE_GPT_CONVERSATIONS";
export const RETRIEVE_GPT_CONVERSATION_DETAIL =
  "RETRIEVE_GPT_CONVERSATION_DETAIL";

//SOLUTIONS
export const LOAD_ALL_SOLUTION = "LOAD_ALL_SOLUTION";
export const LOAD_ALL_SOLUTION_BY_TYPE = "LOAD_ALL_SOLUTION_BY_TYPE";
export const SAVE_SOLUTION = "SAVE_SOLUTION";
export const UPDATE_SOLUTION_BY_ID = "UPDATE_SOLUTION_BY_ID";
export const DELETE_SOLUTION = "DELETE_SOLUTION";
export const LOAD_SOLUTION_BY_ID = "LOAD_SOLUTION_BY_ID";
export const LOAD_RELATED_SOLUTIONS_BY_TYPE = "LOAD_RELATED_SOLUTIONS_BY_TYPE";
export const LOAD_ALL_SOLUTION_BY_DEV = "LOAD_ALL_SOLUTION_BY_DEV";
export const PUBLISH_SOLUTION_BY_ID = "PUBLISH_SOLUTION_BY_ID";
export const DRAFT_SOLUTION_BY_ID = "DRAFT_SOLUTION_BY_ID";
export const LOAD_DEV_SOLUTION_BY_TYPE = "LOAD_DEV_SOLUTION_BY_TYPE";
export const LOAD_PUBLIC_SOLUTIONS = "LOAD_PUBLIC_SOLUTIONS";
export const LOAD_SOLUTION_DETAILS = "LOAD_SOLUTION_DETAILS";
export const SOLUTION_HAS_PROJECT = "SOLUTION_HAS_PROJECT";
export const RESET_SOLUTION_HAS_PROJECT = "RESET_SOLUTION_HAS_PROJECT";
export const RESET_SELECTED_SOLUTION = "RESET_SELECTED_SOLUTION";

//REGION
export const LOAD_REGION_BY_CLOUD_PROVIDER = "LOAD_REGION_BY_CLOUD_PROVIDER";
export const LOAD_REGION_BY_ID = "LOAD_REGION_BY_ID";

//RESOURCEMETADATA
export const LOAD_ALL_RESOURCEMETADATA = "LOAD_ALL_RESOURCEMETADATA";
export const LOAD_RESOURCEMETADATA_BY_TYPE = "LOAD_RESOURCEMETADATA_BY_TYPE";

//CATEGORY
export const LOAD_ALL_CATEGORY = "LOAD_ALL_CATEGORY";
export const SAVE_CATEGORY = "SAVE_CATEGORY";

// SUBSCRIPTION
export const LOAD_ALL_SUBSCRIPTION = "LOAD_ALL_CATEGORY";
export const SAVE_SUBSCRIPTION = "SAVE_SUBSCRIPTION";
export const UPDATE_SUBSCRIPTION_FEE = "UPDATE_SUBSCRIPTION_FEE";

// INVOICES
export const LOAD_ALL_INVOICE = "LOAD_ALL_INVOICE";
export const LOAD_INVOICE_BY_ID = "LOAD_INVOICE_BY_ID";
export const PAY_INVOICE_BY_ID = "PAY_INVOICE_BY_ID";

// SEGMENT
export const LOAD_ALL_SEGMENT = "LOAD_ALL_SEGMENT";
export const SAVE_SEGMENT = "SAVE_SEGMENT";

// WALLET
export const CHECK_WALLET_PASSCODE = "CHECK_WALLET_PASSCODE";
export const REGISTER_WALLET_PASSCODE = "REGISTER_WALLET_PASSCODE";
export const ACCESS_WALLET = "ACCESS_WALLET";
export const WALLET_REVOKE = "WALLET_REVOKE";
export const LOAD_ACCOUNT_BALANCE = "LOAD_ACCOUNT_BALANCE";
export const CHECK_BALANCE = "CHECK_BALANCE";
export const ADD_WALLET_ACCOUNT = "ADD_WALLET_ACCOUNT";
export const LOAD_ALL_WALLET_ACCOUNT = "LOAD_ALL_WALLET_ACCOUNT";
export const WALLET_BUY_ASSET = "WALLET_BUY_ASSET";
export const BUY_AUTHORIZE = "BUY_AUTHORIZE";
export const LOAD_ALL_WALLET_TRANSACTION = "LOAD_ALL_WALLET_TRANSACTION";
export const GET_WALLET_ACCOUNT_DETAILS = "GET_WALLET_ACCOUNT_DETAILS";
export const WALLET_CREATE_ACCOUNT_FROM_SOURCE =
  "WALLET_CREATE_ACCOUNT_FROM_SOURCE";
export const WALLET_CREATE_ACCOUNT_WITHOUT_SOURCE =
  "WALLET_CREATE_ACCOUNT_WITHOUT_SOURCE";
export const WALLET_CHECK_QUOTE = "WALLET_CHECK_QUOTE";
export const WALLET_SWAP_ASSET = "WALLET_SWAP_ASSET";
export const WALLET_SEND_ASSET = "WALLET_SEND_ASSET";
export const GET_WALLET_TRANSACTION_DETAILS = "GET_WALLET_TRANSACTION_DETAILS";
export const GET_WALLET_SWAP_RATE = "GET_WALLET_SWAP_RATE";
export const EDIT_WALLET_ACCOUNT = "EDIT_WALLET_ACCOUNT";
export const REMOVE_WALLET_ACCOUNT = "REMOVE_WALLET_ACCOUNT";
export const GET_WALLET_TRANSACTION = "GET_WALLET_TRANSACTION";
export const GET_WALLET_CLAIMABLE_BALANCE = "GET_WALLET_CLAIMABLE_BALANCE";
export const WALLET_CLAIM_CLAIMABLE_BALANCE = "WALLET_CLAIM_CLAIMABLE_BALANCE";
export const GET_WALLET_CLAIMABLE_BALANCE_RECIEVED_HISTORY =
  "GET_WALLET_CLAIMABLE_BALANCE_RECIEVED_HISTORY";
export const WALLET_SECRET_KEY_SAVED_CONSENT =
  "WALLET_SECRET_KEY_SAVED_CONSENT";
export const LOAD_WALLET_BUY_HISTORY_BY_ACCOUNT =
  "LOAD_WALLET_BUY_HISTORY_BY_ACCOUNT";
export const LOAD_WALLET_REWARD_PERCENTAGES = "LOAD_WALLET_REWARD_PERCENTAGES";
export const LOAD_WALLET_APPLICABLE_REWARD_INFO =
  "LOAD_WALLET_APPLICABLE_REWARD_INFO";
export const WALLET_SHOW_SECRET_KEY = "WALLET_SHOW_SECRET_KEY";
export const LOAD_ALL_WALLET_ASSET = "LOAD_ALL_WALLET_ASSET";
export const UPDATE_WALLET_WYRE_ORDER = "UPDATE_WALLET_WYRE_ORDER";

// UPLOAD
export const SAVE_CONTENT = "SAVE_CONTENT";
export const LOAD_ALL_CONTENT = "LOAD_ALL_CONTENT";
export const LOAD_SINGLE_CONTENT = "LOAD_SINGLE_CONTENT";

// BANKS
export const LOAD_ALL_BANK_ACCOUNT = "LOAD_ALL_BANK_ACCOUNT";
export const LOAD_BANK_ACCOUNT_BY_ID = "LOAD_BANK_ACCOUNT_BY_ID";

// JOBS
export const LOAD_ALL_JOB = "LOAD_ALL_JOB";
export const RERUN_JOB_BY_ID = "RERUN_JOB_BY_ID";

// MEMBERS
export const LOAD_ALL_MEMBERS = "LOAD_ALL_MEMBERS";
export const LOAD_MEMBER_BY_ID = "LOAD_MEMBER_BY_ID";
export const SEND_MEMBER_RE_INVITATION = "SEND_MEMBER_RE_INVITATION";
export const SEND_MEMBER_INVITATION = "SEND_MEMBER_INVITATION";
export const UPDATE_MEMBER = "UPDATE_MEMBER";
export const MEMBER_SETUP = "MEMBER_SETUP";

// LOCALE
export const CHANGE_LOCALE = "CHANGE_LOCALE";

// ITEMS
export const GET_ITEMS_DETAILS_BY_IDS = "GET_ITEMS_DETAILS_BY_IDS";

// Questionnaire
export const GET_QUESTIONNAIRE_LOG = "GET_QUESTIONNAIRE_LOG";

//Settings
export const CREATE_SETTINGS = "CREATE_SETTINGS";
export const UPDATE_SETTINGS = "UPDATE_SETTINGS";
export const GET_SETTINGS = "GET_SETTINGS";