var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "50%", persistent: "" },
      model: {
        value: _vm.open,
        callback: function ($$v) {
          _vm.open = $$v
        },
        expression: "open",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v(" " + _vm._s(_vm.title) + " ")]),
          _c("v-divider"),
          _c("v-card-text", { staticClass: "py-2" }, [
            _c(
              "div",
              [
                _c("v-data-table", {
                  attrs: {
                    headers: _vm.fileGeneratedHistoryHeaders,
                    items: _vm.fileGeneratedHistory,
                    loading: _vm.fileGeneratedHistoryLoading,
                    "server-items-length": _vm.totalHits,
                    "items-per-page": _vm.itemsPerPage,
                    page: _vm.page,
                    "no-data-text": _vm.$vuetify.lang.t(
                      "$vuetify.no_data_available"
                    ),
                    "footer-props": {
                      itemsPerPageOptions: [5, 10],
                      showFirstLastPage: true,
                    },
                  },
                  on: {
                    "update:page": function ($event) {
                      _vm.page = $event
                    },
                    "update:items-per-page": function ($event) {
                      _vm.itemsPerPage = $event
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "body",
                      fn: function ({ items }) {
                        return [
                          _c(
                            "tbody",
                            _vm._l(items, function (item) {
                              return _c(
                                "tr",
                                { key: item.id },
                                [
                                  item.status === "success" ||
                                  item.status === "pending"
                                    ? [
                                        _c("td", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.formatDate(
                                                  _vm.extractMessageProperty(
                                                    item.message,
                                                    "start_date"
                                                  )
                                                )
                                              ) +
                                              " ~ " +
                                              _vm._s(
                                                _vm.formatDate(
                                                  _vm.extractMessageProperty(
                                                    item.message,
                                                    "end_date"
                                                  )
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]),
                                        _c("td", [
                                          _c(
                                            "span",
                                            { staticClass: "text-capitalize" },
                                            [_vm._v(_vm._s(item.status))]
                                          ),
                                        ]),
                                        _c("td", [
                                          _vm.extractMessageProperty(
                                            item.message,
                                            "file_url"
                                          ) === "N/A" ||
                                          _vm.isExpired(
                                            _vm.extractMessageProperty(
                                              item.message,
                                              "expiration"
                                            )
                                          )
                                            ? _c("span", [_vm._v("N/A")])
                                            : _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: _vm.extractMessageProperty(
                                                      item.message,
                                                      "file_url"
                                                    ),
                                                    target: "_blank",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$vuetify.lang.t(
                                                          "$vuetify.download_btn_txt"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.isExpired(
                                                  _vm.extractMessageProperty(
                                                    item.message,
                                                    "expiration"
                                                  )
                                                )
                                                  ? _vm.$vuetify.lang.t(
                                                      "$vuetify.logs_file_generation.expired"
                                                    )
                                                  : _vm.formatDate(
                                                      _vm.extractMessageProperty(
                                                        item.message,
                                                        "expiration"
                                                      )
                                                    )
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ]
                                    : [
                                        _c(
                                          "td",
                                          {
                                            staticClass: "text-center",
                                            attrs: {
                                              colspan:
                                                _vm.fileGeneratedHistoryHeaders
                                                  .length,
                                            },
                                          },
                                          [_vm._v(" File generation failed. ")]
                                        ),
                                      ],
                                ],
                                2
                              )
                            }),
                            0
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ]),
          _c("v-divider"),
          _c(
            "v-card-actions",
            { staticClass: "justify-end" },
            [
              _c(
                "v-btn",
                {
                  attrs: { outlined: "", color: "error" },
                  on: { click: _vm.closeModal },
                },
                [_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.close_btn_txt")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }