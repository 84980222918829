var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      [
        _c(
          "div",
          [
            _c("v-divider"),
            _c(
              "v-row",
              [
                _c("v-col", { attrs: { xs: "6", md: "6", sm: "6" } }, [
                  _c(
                    "div",
                    { staticClass: "px-2 primary--text font-weight-bold" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$vuetify.lang.t(
                              "$vuetify.catalog.catalog_items"
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                ]),
                _c(
                  "v-col",
                  { attrs: { md: "6" } },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "text-capitalize float-right",
                        attrs: { color: "error", outlined: "" },
                        on: { click: _vm.handleDeleteClick },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$vuetify.lang.t("$vuetify.tooltip.delete")
                            ) +
                            " (" +
                            _vm._s(_vm.selectedDocuments.length || "All") +
                            ") "
                        ),
                        _c(
                          "v-icon",
                          { staticClass: "ml-2", attrs: { right: "" } },
                          [_vm._v("mdi-delete")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("v-divider"),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "3" } },
                  [
                    _c(
                      "v-menu",
                      {
                        ref: "menuDates",
                        attrs: {
                          "close-on-content-click": false,
                          "return-value": _vm.dates,
                          transition: "scale-transition",
                          "offset-y": "",
                          "min-width": "auto",
                        },
                        on: {
                          "update:returnValue": function ($event) {
                            _vm.dates = $event
                          },
                          "update:return-value": function ($event) {
                            _vm.dates = $event
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "v-text-field",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          placeholder: _vm.$vuetify.lang.t(
                                            "$vuetify.daterange_placeholder_txt"
                                          ),
                                          "prepend-icon": "mdi-calendar",
                                          readonly: "",
                                          outlined: "",
                                          dense: "",
                                          "hide-details": "auto",
                                        },
                                        model: {
                                          value: _vm.dateRangeText,
                                          callback: function ($$v) {
                                            _vm.dateRangeText = $$v
                                          },
                                          expression: "dateRangeText",
                                        },
                                      },
                                      "v-text-field",
                                      attrs,
                                      false
                                    ),
                                    on
                                  )
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.menuDates,
                          callback: function ($$v) {
                            _vm.menuDates = $$v
                          },
                          expression: "menuDates",
                        },
                      },
                      [
                        _c(
                          "v-date-picker",
                          {
                            attrs: {
                              color: "primary",
                              "no-title": "",
                              scrollable: "",
                              range: "",
                              max: _vm.dateOfToday(),
                            },
                            model: {
                              value: _vm.dates,
                              callback: function ($$v) {
                                _vm.dates = $$v
                              },
                              expression: "dates",
                            },
                          },
                          [
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                attrs: { text: "", color: "error" },
                                on: {
                                  click: function ($event) {
                                    _vm.menuDates = false
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$vuetify.lang.t(
                                        "$vuetify.cancel_btn_txt"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: { text: "", color: "primary" },
                                on: { click: _vm.setDates },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$vuetify.lang.t("$vuetify.OK_btn_txt")
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "3" } },
                  [
                    _c("v-text-field", {
                      attrs: {
                        label: _vm.$vuetify.lang.t("$vuetify.insights.search"),
                        outlined: "",
                        dense: "",
                        "single-line": "",
                        "hide-details": "",
                        "append-icon": "mdi-magnify",
                      },
                      on: { input: _vm.debouncedFetchCatalogItems },
                      model: {
                        value: _vm.searchText,
                        callback: function ($$v) {
                          _vm.searchText = $$v
                        },
                        expression: "searchText",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "6" } },
                  [
                    _c("v-range-slider", {
                      staticClass: "align-center",
                      attrs: {
                        max: _vm.maxPrice,
                        min: _vm.minPrice,
                        step: _vm.step,
                        "hide-details": "",
                      },
                      on: { change: _vm.debouncedPriceSort },
                      scopedSlots: _vm._u([
                        {
                          key: "prepend",
                          fn: function () {
                            return [
                              _c("v-text-field", {
                                staticClass: "mt-0 pt-0",
                                attrs: {
                                  dense: "",
                                  outlined: "",
                                  "hide-details": "",
                                  "single-line": "",
                                  type: "number",
                                  readonly: "",
                                },
                                model: {
                                  value: _vm.priceRange[0],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.priceRange, 0, _vm._n($$v))
                                  },
                                  expression: "priceRange[0]",
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "append",
                          fn: function () {
                            return [
                              _c("v-text-field", {
                                staticClass: "mt-0 pt-0",
                                attrs: {
                                  dense: "",
                                  outlined: "",
                                  "hide-details": "",
                                  "single-line": "",
                                  type: "number",
                                  readonly: "",
                                },
                                model: {
                                  value: _vm.priceRange[1],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.priceRange, 1, _vm._n($$v))
                                  },
                                  expression: "priceRange[1]",
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                      model: {
                        value: _vm.priceRange,
                        callback: function ($$v) {
                          _vm.priceRange = $$v
                        },
                        expression: "priceRange",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("v-divider"),
            _vm.isLoading
              ? _c("Loader", {
                  attrs: { "loader-type": "table-row-divider@10" },
                })
              : _c("v-data-table", {
                  attrs: {
                    headers: _vm.catalogItemHeaders,
                    loading: _vm.loading,
                    items: _vm.catalogTableItems,
                    "items-per-page": _vm.itemsPerPage,
                    page: _vm.page,
                    search: _vm.search,
                    "sort-by": _vm.sortBy,
                    "sort-desc": _vm.sortDesc,
                    "must-sort": true,
                    "show-select": "",
                    "hide-default-footer": "",
                  },
                  on: {
                    "update:page": function ($event) {
                      _vm.page = $event
                    },
                    "update:sortBy": function ($event) {
                      _vm.sortBy = $event
                    },
                    "update:sort-by": function ($event) {
                      _vm.sortBy = $event
                    },
                    "update:sortDesc": function ($event) {
                      _vm.sortDesc = $event
                    },
                    "update:sort-desc": [
                      function ($event) {
                        _vm.sortDesc = $event
                      },
                      _vm.updateSort,
                    ],
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: `item.item_id`,
                        fn: function ({ item }) {
                          return [
                            _c("span", [
                              _vm._v(_vm._s(item.item[_vm.mapper?.item_id])),
                            ]),
                          ]
                        },
                      },
                      {
                        key: `item.title`,
                        fn: function ({ item }) {
                          return [
                            _c("span", [
                              _vm._v(_vm._s(item.item[_vm.mapper?.title])),
                            ]),
                          ]
                        },
                      },
                      {
                        key: `item.availability`,
                        fn: function ({ item }) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(item.item[_vm.mapper?.availability])
                              ),
                            ]),
                          ]
                        },
                      },
                      {
                        key: `item.price`,
                        fn: function ({ item }) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  new Intl.NumberFormat().format(
                                    item.item[_vm.mapper?.price]
                                  )
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                      {
                        key: `item.date`,
                        fn: function ({ item }) {
                          return [
                            _c("span", [
                              _vm._v(_vm._s(_vm.formatDateTime(item.date))),
                            ]),
                          ]
                        },
                      },
                      {
                        key: `item.actions`,
                        fn: function ({ item }) {
                          return [
                            _c("ButtonGroup", {
                              attrs: {
                                groupType: _vm.ACTION_BUTTON_GROUP,
                                addButton: false,
                                stopButton: false,
                                startButton: false,
                              },
                              on: {
                                onDetailsClick: function ($event) {
                                  return _vm.detail_modal(item)
                                },
                                onEditClick: function ($event) {
                                  return _vm.updateItem(item)
                                },
                                onDeleteClick: function ($event) {
                                  return _vm.deleteItem(item)
                                },
                              },
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                  model: {
                    value: _vm.selectedDocuments,
                    callback: function ($$v) {
                      _vm.selectedDocuments = $$v
                    },
                    expression: "selectedDocuments",
                  },
                }),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { staticClass: "py-0", attrs: { cols: "12", md: "12" } },
                  [_c("v-divider")],
                  1
                ),
                _c(
                  "v-col",
                  {
                    staticClass: "d-flex",
                    attrs: { cols: "12", xs: "12", sm: "6", md: "4" },
                  },
                  [
                    !_vm.isLoading
                      ? _c("span", { staticClass: "ma-2 font-weight-bold" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$vuetify.lang.t("$vuetify.common.page_no")
                              ) +
                              " : "
                          ),
                          _c("span", { staticClass: "primary--text" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.catalogScrollIdStack.length) +
                                " / " +
                                _vm._s(_vm.pageCount) +
                                " "
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                ),
                _c(
                  "v-col",
                  {
                    staticClass: "d-flex",
                    attrs: { cols: "12", xs: "12", sm: "6", md: "6" },
                  },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "ma-2",
                        attrs: {
                          color: "primary",
                          disabled:
                            _vm.boundary === "left" ||
                            _vm.boundary === "both" ||
                            _vm.isLoading,
                        },
                        on: { click: _vm.handlePreviousPage },
                      },
                      [
                        _c("v-icon", { attrs: { left: "" } }, [
                          _vm._v("mdi-chevron-left"),
                        ]),
                        _vm._v(" Previous "),
                      ],
                      1
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "ma-2",
                        attrs: {
                          color: "primary",
                          disabled:
                            _vm.boundary === "right" ||
                            _vm.boundary === "both" ||
                            _vm.isLoading,
                        },
                        on: { click: _vm.handleNextPage },
                      },
                      [
                        _vm._v(" Next "),
                        _c("v-icon", { attrs: { right: "" } }, [
                          _vm._v("mdi-chevron-right"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  {
                    staticClass: "d-flex justify-end",
                    attrs: { cols: "12", xs: "6", sm: "3", md: "2" },
                  },
                  [
                    _c("v-select", {
                      attrs: {
                        items: _vm.rows,
                        "hide-details": "auto",
                        outlined: "",
                        dense: "",
                      },
                      model: {
                        value: _vm.itemsPerPage,
                        callback: function ($$v) {
                          _vm.itemsPerPage = $$v
                        },
                        expression: "itemsPerPage",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-dialog",
              {
                attrs: { "max-width": "800" },
                model: {
                  value: _vm.openEditModal,
                  callback: function ($$v) {
                    _vm.openEditModal = $$v
                  },
                  expression: "openEditModal",
                },
              },
              [
                _c(
                  "v-card",
                  { staticClass: "d-flex flex-column" },
                  [
                    _c(
                      "v-card-text",
                      [
                        _c(
                          "v-container",
                          { attrs: { fluid: "" } },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  [
                                    _c(
                                      "v-list",
                                      [
                                        _c(
                                          "v-list-item",
                                          [
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-list-item-title", [
                                                  _c(
                                                    "h1",
                                                    {
                                                      staticClass:
                                                        "text-h6 text-sm-h5 text-md-h4 text-lg-h5",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$vuetify.lang.t(
                                                              "$vuetify.catalog.edit_catalog_items"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("v-divider"),
                                        _vm._l(
                                          _vm.selectedItem.item,
                                          function (value, key) {
                                            return _c(
                                              "v-list-item",
                                              { key: key },
                                              [
                                                _c(
                                                  "v-list-item-content",
                                                  [
                                                    _c(
                                                      "v-list-item-title",
                                                      {
                                                        staticClass:
                                                          "d-flex flex-wrap",
                                                      },
                                                      [
                                                        _c("v-textarea", {
                                                          staticClass: "mt-2",
                                                          attrs: {
                                                            label:
                                                              _vm.formatKey(
                                                                key
                                                              ),
                                                            outlined: "",
                                                            dense: "",
                                                            "hide-details": "",
                                                            "max-rows": "8",
                                                            "auto-grow": "",
                                                            rows: _vm.dynamicRowCount,
                                                            clearable: "",
                                                            "clear-icon":
                                                              "mdi-close-circle",
                                                            color: "primary",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.selectedItem
                                                                .item[key],
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.selectedItem
                                                                  .item,
                                                                key,
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "selectedItem.item[key]",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("v-divider"),
                    _c(
                      "v-card-actions",
                      { staticClass: "justify-end" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "error", text: "" },
                            on: { click: _vm.close },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$vuetify.lang.t("$vuetify.common.cancel")
                              )
                            ),
                          ]
                        ),
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "primary", text: "" },
                            on: { click: _vm.saveUpdatedItem },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$vuetify.lang.t("$vuetify.common.save")
                              )
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-dialog",
              {
                attrs: { "max-width": "800" },
                model: {
                  value: _vm.openDetailModal,
                  callback: function ($$v) {
                    _vm.openDetailModal = $$v
                  },
                  expression: "openDetailModal",
                },
              },
              [
                _c(
                  "v-card",
                  {
                    staticClass: "d-flex flex-column",
                    staticStyle: {
                      "overflow-y": "scroll",
                      "overflow-x": "hidden",
                    },
                  },
                  [
                    _c(
                      "v-card-text",
                      [
                        _c(
                          "v-container",
                          { attrs: { fluid: "" } },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "v-list",
                                      _vm._l(
                                        _vm.modalContent.item,
                                        function (value, key) {
                                          return _c(
                                            "v-list-item",
                                            { key: key },
                                            [
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c(
                                                    "v-list-item-title",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-wrap align-center",
                                                    },
                                                    [
                                                      _c(
                                                        "strong",
                                                        {
                                                          staticClass:
                                                            "mr-2 text-subtitle-1 text-sm-body-1 text-md-body-2 text-lg-subtitle-1 font-weight-bold",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.formatKey(
                                                                  key
                                                                )
                                                              ) +
                                                              ": "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column flex-grow-1",
                                                        },
                                                        [
                                                          _vm.imageKeys.includes(
                                                            key
                                                          )
                                                            ? [
                                                                _c("v-img", {
                                                                  staticClass:
                                                                    "mt-2",
                                                                  attrs: {
                                                                    src: value,
                                                                    "max-width":
                                                                      "300",
                                                                    "max-height":
                                                                      "200",
                                                                    contain: "",
                                                                  },
                                                                }),
                                                              ]
                                                            : _vm.textKeys.includes(
                                                                key
                                                              )
                                                            ? [
                                                                _c("span", {
                                                                  staticClass:
                                                                    "response-details-text text-wrap text-subtitle-1 word-break mt-1",
                                                                  domProps: {
                                                                    innerHTML:
                                                                      _vm._s(
                                                                        value
                                                                      ),
                                                                  },
                                                                }),
                                                              ]
                                                            : [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "response-details-text text-wrap text-subtitle-1 word-break",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          Array.isArray(
                                                                            value
                                                                          )
                                                                            ? value.join(
                                                                                ", "
                                                                              )
                                                                            : typeof value ===
                                                                                "object" &&
                                                                              value !==
                                                                                null
                                                                            ? Object.values(
                                                                                value
                                                                              ).join(
                                                                                ", "
                                                                              )
                                                                            : value
                                                                        )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                        ],
                                                        2
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("v-divider"),
                    _c(
                      "v-card-actions",
                      { staticClass: "justify-end" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "primary", text: "" },
                            on: { click: _vm.close },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$vuetify.lang.t("$vuetify.common.close")
                              )
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      _c("DialogModal", {
        attrs: {
          title: _vm.$vuetify.lang.t("$vuetify.confirm_alert_title_txt"),
          message: _vm.delete_alert_message,
          confirmButtonText: _vm.$vuetify.lang.t("$vuetify.confirm_btn_txt"),
          cancelButtonText: _vm.$vuetify.lang.t("$vuetify.cancel_btn_txt"),
        },
        on: {
          onConfirmation: function ($event) {
            return _vm.removeItems()
          },
          onCancel: function ($event) {
            _vm.deleteDialog = false
          },
        },
        model: {
          value: _vm.deleteDialog,
          callback: function ($$v) {
            _vm.deleteDialog = $$v
          },
          expression: "deleteDialog",
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }