var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        [
          _vm.trainLoader && _vm.updateLoader && _vm.initialSetupDone
            ? _c("Loader", { staticClass: "mr-2 mt-6" })
            : _vm.initialSetupDone
            ? [
                _c(
                  "v-card",
                  { staticClass: "elevation-0" },
                  [
                    _c("v-card-title", { staticClass: "font-weight-bold" }, [
                      _vm._v(
                        _vm._s(
                          `${_vm.$vuetify.lang.t(
                            "$vuetify.project.train_data"
                          )}`
                        )
                      ),
                    ]),
                    _c("v-divider"),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { staticClass: "mx-2" },
                          [
                            [
                              _c("ItemsTrain", {
                                on: {
                                  updateComplete:
                                    _vm.handleTrainHistoryTableComplete,
                                },
                              }),
                              _vm.mapper.detail
                                ? _c(
                                    "div",
                                    [
                                      _c("v-divider"),
                                      _c("ImagesTrain", {
                                        attrs: {
                                          mapper: _vm.mapper,
                                          taskHistoryData: _vm.taskHistoryData,
                                        },
                                        on: {
                                          updateComplete:
                                            _vm.handleTrainHistoryTableComplete,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c("v-divider"),
                              _c("RanksTrain", {
                                on: {
                                  updateComplete:
                                    _vm.handleTrainHistoryTableComplete,
                                },
                              }),
                            ],
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-card",
                  { staticClass: "elevation-0" },
                  [
                    _c(
                      "v-card-title",
                      { staticClass: "text-h6 font-weight-bold" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              `${_vm.$vuetify.lang.t(
                                "$vuetify.project.train_history"
                              )}`
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c("v-divider"),
                    _c(
                      "v-row",
                      { staticClass: "mx-2 mb-3" },
                      [_c("v-col", { attrs: { cols: "12", md: "6" } })],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _vm.isTrainHistoryLoading
                          ? _c("Loader", { staticClass: "mr-2 mt-6" })
                          : [
                              _c(
                                "v-col",
                                { attrs: { xs: "12", md: "4" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.filterTrainHistoryOptions,
                                      label: _vm.$vuetify.lang.t(
                                        "$vuetify.project.train_type"
                                      ),
                                      "item-text": "name",
                                      "item-value": "value",
                                      outlined: "",
                                      dense: "",
                                    },
                                    on: { change: _vm.onFilterTrainTypeChange },
                                    model: {
                                      value: _vm.filterTrainHistoryType,
                                      callback: function ($$v) {
                                        _vm.filterTrainHistoryType = $$v
                                      },
                                      expression: "filterTrainHistoryType",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("v-data-table", {
                                attrs: {
                                  headers: _vm.trainHistoryHeaders,
                                  items: _vm.taskHistoryData,
                                  "sort-by": ["created_at"],
                                  "sort-desc": [true],
                                  "server-items-length":
                                    _vm.totalNumberOfTrains,
                                  "footer-props": {
                                    "items-per-page-options": [
                                      5, 10, 15, 50, 100,
                                    ],
                                  },
                                  options: _vm.paginateTrainHistoryObj,
                                },
                                on: {
                                  "update:options": [
                                    function ($event) {
                                      _vm.paginateTrainHistoryObj = $event
                                    },
                                    _vm.paginateTrainHistory,
                                  ],
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "item.name",
                                    fn: function ({ item }) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              item.name
                                                ? _vm.capitalizeFirstLetter(
                                                    item.name
                                                  )
                                                : "N/A"
                                            ) +
                                            " "
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "item.started_at",
                                    fn: function ({ item }) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              item.started_at
                                                ? _vm.formatDateTime(
                                                    item.started_at
                                                  )
                                                : "N/A"
                                            ) +
                                            " "
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "item.last_updated_at",
                                    fn: function ({ item }) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              item.last_updated_at
                                                ? _vm.formatDateTime(
                                                    item.last_updated_at
                                                  )
                                                : "N/A"
                                            ) +
                                            " "
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "item.message",
                                    fn: function ({ item }) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              item.message
                                                ? item.message
                                                : "N/A"
                                            ) +
                                            " "
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "item.scheduled_by",
                                    fn: function ({ item }) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              item.scheduled_by
                                                ? item.scheduled_by
                                                : "N/A"
                                            ) +
                                            " "
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "item.status",
                                    fn: function ({ item }) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              item.status ? item.status : "N/A"
                                            ) +
                                            " "
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                      ],
                      2
                    ),
                  ],
                  1
                ),
                [
                  _c("UpdateRankSettings", {
                    attrs: { rankSettings: _vm.rankSettings },
                    on: { updateComplete: _vm.handleSettingsUpdate },
                  }),
                  _c("UpdateMapper", {
                    attrs: { mapper: _vm.mapper },
                    on: { updateComplete: _vm.handleMapperUpdateComplete },
                  }),
                ],
              ]
            : _vm.selectedProject.segment.name ===
              _vm.PROJECT_SEGMENTS.PERSONALIZER
            ? [
                _c(
                  "div",
                  { staticClass: "mt-8" },
                  [
                    _c(
                      "v-alert",
                      { attrs: { type: "info", text: "", border: "left" } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              `${_vm.$vuetify.lang.t(
                                "$vuetify.project.settings_initial_setup_info"
                              )}`
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _c(
            "v-card",
            { staticClass: "elevation-0" },
            [
              _c("v-card-title", { staticClass: "text-h6 font-weight-bold" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      `${_vm.$vuetify.lang.t("$vuetify.credentials_label_txt")}`
                    ) +
                    " "
                ),
              ]),
              _c("v-divider"),
              _c(
                "v-row",
                { staticClass: "mx-2 mb-3" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "pa-2", attrs: { flat: "" } },
                        [
                          _c("strong", [
                            _vm._v(
                              _vm._s(
                                `${_vm.$vuetify.lang.t(
                                  "$vuetify.all_credentials_label_txt"
                                )}:`
                              )
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c(
                        "v-row",
                        { staticClass: "justify-end mt-1" },
                        [
                          !_vm.disable_crud_operation
                            ? _c("Button", {
                                attrs: {
                                  buttonType: _vm.FLOATING_ACTION_BUTTON,
                                  iconName: "mdi-plus",
                                  size: "x-small",
                                  buttonText: _vm.$vuetify.lang.t(
                                    "$vuetify.create_action_txt"
                                  ),
                                  color: "primary",
                                },
                                on: {
                                  onClick: function ($event) {
                                    return _vm.createNewClick()
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                [
                  _vm.isLoading
                    ? _c("Loader", { staticClass: "mr-2 mt-6" })
                    : _c("v-data-table", {
                        attrs: {
                          headers: _vm.credentialHeaders,
                          items: _vm.credentials,
                          "sort-by": ["created_at"],
                          "sort-desc": [true],
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "item.id",
                            fn: function ({ item }) {
                              return [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "span",
                                                _vm._g(
                                                  _vm._b(
                                                    {},
                                                    "span",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.id.split("-")[0]
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [_c("span", [_vm._v(_vm._s(item.id))])]
                                ),
                              ]
                            },
                          },
                          {
                            key: "item.name",
                            fn: function ({ item }) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(item.name ? item.name : "N/A") +
                                    " "
                                ),
                              ]
                            },
                          },
                          {
                            key: "item.status",
                            fn: function ({ item }) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.status
                                        ? _vm.capitalizeFirstLetter(item.status)
                                        : "N/A"
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                          {
                            key: "item.security",
                            fn: function ({ item }) {
                              return [
                                _c("ChatHeadTooltip", {
                                  attrs: {
                                    toolTipText:
                                      _vm.getSecurityLevelsDescriptions(
                                        item.security?.level
                                      ),
                                    chipText: item.security?.level,
                                    chipColor: item.security?.color,
                                    textColor: item.security?.textColor,
                                  },
                                }),
                              ]
                            },
                          },
                          {
                            key: "item.created_at",
                            fn: function ({ item }) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.created_at
                                        ? _vm.formatDateTime(item.created_at)
                                        : "N/A"
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                          {
                            key: "item.actions",
                            fn: function ({ item }) {
                              return [
                                _c("ButtonGroup", {
                                  attrs: {
                                    groupType: _vm.ACTION_BUTTON_GROUP,
                                    detailsButton: false,
                                    addButton: false,
                                    deleteButton: false,
                                    startButton:
                                      _vm.disable_crud_operation ||
                                      item.status !== "active",
                                    disabledStartButton:
                                      _vm.disable_crud_operation ||
                                      (item.status !== "active" &&
                                        _vm.selectedProject.status !==
                                          "running"),
                                    disabledEditButton:
                                      _vm.disable_crud_operation ||
                                      _vm.selectedProject.status !== "running",
                                    disabledStopButton:
                                      _vm.disable_crud_operation ||
                                      _vm.selectedProject.status !== "running",
                                    stopButton:
                                      _vm.disable_crud_operation ||
                                      item.status === "active",
                                  },
                                  on: {
                                    onEditClick: function ($event) {
                                      return _vm.editItem(item)
                                    },
                                    onStartClick: function ($event) {
                                      return _vm.startItem(item)
                                    },
                                    onStopClick: function ($event) {
                                      return _vm.stopItem(item)
                                    },
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.selectedProject.segment.name ===
            _vm.PROJECT_SEGMENTS.PERSONALIZER ||
          _vm.selectedProject.segment.name === _vm.PROJECT_SEGMENTS.Maira
            ? [
                _vm.selectedProject.solution_template.sandbox_location
                  ? _c(
                      "v-card",
                      { staticClass: "elevation-0" },
                      [
                        _c(
                          "v-card-title",
                          { staticClass: "font-weight-bold" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$vuetify.lang.t(
                                    "$vuetify.sandbox_btn_txt"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c("v-divider"),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { staticClass: "mx-2" },
                              [
                                [
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "ma-2",
                                          attrs: {
                                            outlined: "",
                                            color: "primary",
                                            href: _vm.selectedProject
                                              .solution_template
                                              .sandbox_location,
                                            target: "_blank",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$vuetify.lang.t(
                                                  "$vuetify.sandbox_btn_txt"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.selectedProject.solution_template.documentation_location
                  ? _c(
                      "v-card",
                      { staticClass: "elevation-0" },
                      [
                        _c(
                          "v-card-title",
                          { staticClass: "font-weight-bold" },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$vuetify.lang.t(
                                  "$vuetify.documentation_btn_txt"
                                )
                              )
                            ),
                          ]
                        ),
                        _c("v-divider"),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { staticClass: "mx-2" },
                              [
                                [
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "ma-2",
                                          attrs: {
                                            outlined: "",
                                            color: "primary",
                                            href: _vm.selectedProject
                                              .solution_template
                                              .documentation_location,
                                            target: "_blank",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$vuetify.lang.t(
                                                  "$vuetify.documentation_btn_txt"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.selectedProject.solution_template.tutorial_location
                  ? _c(
                      "v-card",
                      { staticClass: "elevation-0" },
                      [
                        _c(
                          "v-card-title",
                          { staticClass: "font-weight-bold" },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$vuetify.lang.t("$vuetify.tutorial_btn_txt")
                              )
                            ),
                          ]
                        ),
                        _c("v-divider"),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { staticClass: "mx-2" },
                              [
                                [
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "ma-2",
                                          attrs: {
                                            outlined: "",
                                            color: "primary",
                                            href: _vm.selectedProject
                                              .solution_template
                                              .tutorial_location,
                                            target: "_blank",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$vuetify.lang.t(
                                                  "$vuetify.tutorial_btn_txt"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "v-form",
        {
          ref: "form",
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c(
            "v-dialog",
            {
              attrs: { width: "500" },
              model: {
                value: _vm.dialog,
                callback: function ($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "div",
                    { staticClass: "px-3 py-3 primary--text font-weight-bold" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.edit
                              ? _vm.$vuetify.lang.t(
                                  "$vuetify.edit_credential_label_txt"
                                )
                              : _vm.$vuetify.lang.t(
                                  "$vuetify.add_credential_label_txt"
                                )
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("v-divider"),
                  _c(
                    "v-layout",
                    { staticClass: "px-5 py-5" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  inputType: _vm.TEXT,
                                  label: _vm.$vuetify.lang.t(
                                    "$vuetify.name_header_txt"
                                  ),
                                  rules: [
                                    (v) =>
                                      !!v ||
                                      _vm.$vuetify.lang.t(
                                        "$vuetify.credentials_error_msg_txt"
                                      ),
                                  ],
                                  outlined: "",
                                  dense: "",
                                },
                                model: {
                                  value: _vm.credentialName,
                                  callback: function ($$v) {
                                    _vm.credentialName = $$v
                                  },
                                  expression: "credentialName",
                                },
                              }),
                              [
                                _c(
                                  "div",
                                  [
                                    _c("v-select", {
                                      key: _vm.dialog,
                                      attrs: {
                                        label: _vm.$vuetify.lang.t(
                                          "$vuetify.key_type_selector_txt"
                                        ),
                                        items: _vm.credentialTypeOptions,
                                        disabled: _vm.edit,
                                        variant: "solo-filled",
                                        outlined: "",
                                        dense: "",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "append",
                                          fn: function () {
                                            return [
                                              !_vm.edit
                                                ? _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { top: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function ({
                                                              on,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "v-avatar",
                                                                  _vm._g(
                                                                    {},
                                                                    on
                                                                  ),
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-information"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        3557878223
                                                      ),
                                                    },
                                                    [
                                                      _vm.credentialType ===
                                                      "project"
                                                        ? _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$vuetify.lang.t(
                                                                  "$vuetify.project_api_key_tooltip_txt"
                                                                )
                                                              )
                                                            ),
                                                          ])
                                                        : _vm.credentialType ===
                                                          "client"
                                                        ? _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$vuetify.lang.t(
                                                                  "$vuetify.client_key_tooltip_txt"
                                                                )
                                                              )
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c("v-icon", [
                                                _vm._v("mdi-chevron-down"),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                      model: {
                                        value: _vm.credentialType,
                                        callback: function ($$v) {
                                          _vm.credentialType = $$v
                                        },
                                        expression: "credentialType",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              [
                                _c(
                                  "div",
                                  [
                                    _c("v-combobox", {
                                      attrs: {
                                        label: "Whitelisted Domain",
                                        placeholder: _vm.$vuetify.lang.t(
                                          "$vuetify.whitelisted_domain_placeholder_txt"
                                        ),
                                        multiple: "",
                                        "small-chips": "",
                                        outlined: "",
                                        "append-icon": "",
                                        "deletable-chips": "",
                                        clearable: "",
                                        height: "90",
                                        rules: [_vm.validateWhitelistedDomain],
                                      },
                                      on: {
                                        keydown: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "space",
                                              32,
                                              $event.key,
                                              [" ", "Spacebar"]
                                            )
                                          )
                                            return null
                                          $event.preventDefault()
                                        },
                                      },
                                      model: {
                                        value: _vm.whitelistedDomainShow,
                                        callback: function ($$v) {
                                          _vm.whitelistedDomainShow = $$v
                                        },
                                        expression: "whitelistedDomainShow",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              _vm.whitelistedDomainShow.length === 0
                                ? _c(
                                    "v-alert",
                                    {
                                      attrs: {
                                        outlined: "",
                                        dense: "",
                                        prominent: "",
                                        text: "",
                                        border: "left",
                                        type: "warning",
                                      },
                                    },
                                    [
                                      _c("span", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.$vuetify.lang.t(
                                              "$vuetify.whitelisted_domain_security_alert_txt"
                                            )
                                          ),
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c("Button", {
                        staticClass: "red--text font-weight-bold",
                        attrs: {
                          buttonType: _vm.TEXT_BUTTON,
                          buttonText: _vm.$vuetify.lang.t(
                            "$vuetify.cancel_btn_txt"
                          ),
                          color: "red",
                        },
                        on: {
                          onClick: function ($event) {
                            _vm.dialog = false
                          },
                        },
                      }),
                      _c("Button", {
                        staticClass: "font-weight-bold",
                        attrs: {
                          buttonType: _vm.RAISED_BUTTON,
                          buttonText: _vm.$vuetify.lang.t(
                            "$vuetify.save_btn_txt"
                          ),
                          disabled: !_vm.valid,
                          color: _vm.valid ? "primary" : "#90A4AE",
                          loading: _vm.isLoading,
                        },
                        on: {
                          onClick: function ($event) {
                            return _vm.saveCredentialClick()
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "600" },
          model: {
            value: _vm.successDialog,
            callback: function ($$v) {
              _vm.successDialog = $$v
            },
            expression: "successDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "headline white--text text--primary mb-4" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$vuetify.lang.t("$vuetify.credentials_label_txt")
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "div",
                    { staticClass: "mb-8" },
                    [
                      _c("v-alert", { attrs: { type: "info", outlined: "" } }, [
                        _vm._v(" " + _vm._s(_vm.downloadMessage) + " "),
                      ]),
                    ],
                    1
                  ),
                  _vm.credentialType === "client"
                    ? _c("v-text-field", {
                        attrs: { label: "client key", outlined: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "append",
                              fn: function () {
                                return [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on }) {
                                              return [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.copyClientKey()
                                                        },
                                                      },
                                                    },
                                                    on
                                                  ),
                                                  [_vm._v("mdi-content-copy")]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2743972038
                                      ),
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.client_key_copy
                                              ? _vm.$vuetify.lang.t(
                                                  "$vuetify.copied_msg_txt"
                                                )
                                              : _vm.$vuetify.lang.t(
                                                  "$vuetify.copy_msg_txt"
                                                )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          234614673
                        ),
                        model: {
                          value: _vm.client_key,
                          callback: function ($$v) {
                            _vm.client_key = $$v
                          },
                          expression: "client_key",
                        },
                      })
                    : _c(
                        "div",
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$vuetify.lang.t(
                                "$vuetify.project_key_label_txt"
                              ),
                              outlined: "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "append",
                                fn: function () {
                                  return [
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "" },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "activator",
                                            fn: function ({ on }) {
                                              return [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.copyProjectKey()
                                                        },
                                                      },
                                                    },
                                                    on
                                                  ),
                                                  [_vm._v(" mdi-content-copy ")]
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.project_key_copy
                                                ? _vm.$vuetify.lang.t(
                                                    "$vuetify.copied_msg_txt"
                                                  )
                                                : _vm.$vuetify.lang.t(
                                                    "$vuetify.copy_msg_txt"
                                                  )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.project_key,
                              callback: function ($$v) {
                                _vm.project_key = $$v
                              },
                              expression: "project_key",
                            },
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$vuetify.lang.t(
                                "$vuetify.api_key_label_txt"
                              ),
                              outlined: "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "append",
                                fn: function () {
                                  return [
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "" },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "activator",
                                            fn: function ({ on }) {
                                              return [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.copyApiKey()
                                                        },
                                                      },
                                                    },
                                                    on
                                                  ),
                                                  [_vm._v(" mdi-content-copy ")]
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.api_key_copy
                                                ? _vm.$vuetify.lang.t(
                                                    "$vuetify.copied_msg_txt"
                                                  )
                                                : _vm.$vuetify.lang.t(
                                                    "$vuetify.copy_msg_txt"
                                                  )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.api_key,
                              callback: function ($$v) {
                                _vm.api_key = $$v
                              },
                              expression: "api_key",
                            },
                          }),
                        ],
                        1
                      ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.downloadCredential()
                        },
                      },
                    },
                    [
                      _c("v-icon", [_vm._v("mdi-download")]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$vuetify.lang.t(
                              "$vuetify.download_json_msg_txt"
                            )
                          ) +
                          " "
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c("Button", {
                    attrs: {
                      buttonType: _vm.TEXT_BUTTON,
                      buttonText: _vm.$vuetify.lang.t("$vuetify.close_btn_txt"),
                      color: "primary",
                    },
                    on: {
                      onClick: function ($event) {
                        _vm.successDialog = false
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }