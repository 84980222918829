var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.groupType === _vm.ACTION_BUTTON_GROUP
    ? _c("ActionButtonGroup", {
        attrs: {
          detailsButton: _vm.detailsButton,
          editButton: _vm.editButton,
          documentButton: _vm.documentButton,
          deleteButton: _vm.deleteButton,
          addButton: _vm.addButton,
          stopButton: _vm.stopButton,
          startButton: _vm.startButton,
          downloadButton: _vm.downloadButton,
          trainButton: _vm.trainButton,
          disabledDetailsButton: _vm.disabledDetailsButton,
          disabledEditButton: _vm.disabledEditButton,
          disabledDeleteButton: _vm.disabledDeleteButton,
          disabledAddButton: _vm.disabledAddButton,
          disabledStartButton: _vm.disabledStartButton,
          disabledStopButton: _vm.disabledStopButton,
          disabledDownloadButton: _vm.disabledDownloadButton,
        },
        on: {
          onDetailsClick: function ($event) {
            return _vm.onDetailsClick()
          },
          onEditClick: function ($event) {
            return _vm.onEditClick()
          },
          onAddClick: function ($event) {
            return _vm.onAddClick()
          },
          onDocumentClick: function ($event) {
            return _vm.onDocumentClick()
          },
          onDeleteClick: function ($event) {
            return _vm.onDeleteClick()
          },
          onStopClick: function ($event) {
            return _vm.onStopClick()
          },
          onStartClick: function ($event) {
            return _vm.onStartClick()
          },
          onDownloadClick: function ($event) {
            return _vm.onDownloadClick()
          },
          onTrainClick: function ($event) {
            return _vm.onTrainClick()
          },
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }