var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        [
          _c(
            "v-card",
            [
              _c(
                "v-layout",
                { attrs: { row: "", "justify-space-between": "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", md12: "" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "pa-2 mr-2", attrs: { outlined: "" } },
                        [
                          _vm.isSetupStateLoading ||
                          _vm.isLoading ||
                          _vm.isLoadingData
                            ? [_c("Loader", { staticClass: "mr-2 mt-6" })]
                            : [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "v-tabs",
                                      {
                                        attrs: {
                                          centered: _vm.centered,
                                          grow: _vm.grow,
                                          vertical: _vm.vertical,
                                          right: _vm.right,
                                          "prev-icon": _vm.prevIcon
                                            ? "mdi-arrow-left-bold-box-outline"
                                            : undefined,
                                          "next-icon": _vm.nextIcon
                                            ? "mdi-arrow-right-bold-box-outline"
                                            : undefined,
                                          "icons-and-text": _vm.icons,
                                        },
                                        model: {
                                          value: _vm.tab,
                                          callback: function ($$v) {
                                            _vm.tab = $$v
                                          },
                                          expression: "tab",
                                        },
                                      },
                                      [
                                        _c("v-tabs-slider"),
                                        _c(
                                          "v-tab",
                                          {
                                            key: "details",
                                            attrs: { href: "#details" },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$vuetify.lang.t(
                                                    "$vuetify.details_step_txt"
                                                  )
                                                ) +
                                                " "
                                            ),
                                            _vm.icons
                                              ? _c("v-icon", [
                                                  _vm._v("mdi-phone"),
                                                ])
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-tab",
                                          {
                                            key: "settings",
                                            attrs: { href: "#settings" },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$vuetify.lang.t(
                                                    "$vuetify.profile_settings_tab"
                                                  )
                                                ) +
                                                " "
                                            ),
                                            _vm.icons
                                              ? _c("v-icon", [
                                                  _vm._v("mdi-phone"),
                                                ])
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-tab",
                                          {
                                            key: "insights",
                                            attrs: { href: "#insights" },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$vuetify.lang.t(
                                                    "$vuetify.insights_step_txt"
                                                  )
                                                ) +
                                                " "
                                            ),
                                            _vm.icons
                                              ? _c("v-icon", [
                                                  _vm._v("mdi-phone"),
                                                ])
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _vm.selectedProject.segment.name ===
                                        _vm.PROJECT_SEGMENTS.PERSONALIZER
                                          ? _c(
                                              "v-tab",
                                              {
                                                key: "setup",
                                                attrs: {
                                                  href: !_vm.initialSetupDoneFunction
                                                    ? "#setup"
                                                    : "#dataUpdate",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      !_vm.initialSetupDoneFunction
                                                        ? _vm.$vuetify.lang.t(
                                                            "$vuetify.setup_step_txt"
                                                          )
                                                        : _vm.$vuetify.lang.t(
                                                            "$vuetify.project.dataset"
                                                          )
                                                    ) +
                                                    " "
                                                ),
                                                _vm.icons
                                                  ? _c("v-icon", [
                                                      _vm._v("mdi-phone"),
                                                    ])
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.selectedProject.segment.name ===
                                          _vm.PROJECT_SEGMENTS.Maira &&
                                        !_vm.isGptInitialSetupCompleted
                                          ? _c(
                                              "v-tab",
                                              {
                                                key: "gptSetupTab",
                                                attrs: { href: "#gptSetupTab" },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$vuetify.lang.t(
                                                        "$vuetify.setup_step_txt"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _vm.icons
                                                  ? _c("v-icon", [
                                                      _vm._v("mdi-phone"),
                                                    ])
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        (_vm.selectedProject.segment.name ===
                                          _vm.PROJECT_SEGMENTS.Maira &&
                                          _vm.isGptInitialSetupCompleted) ||
                                        (_vm.selectedProject.segment.name ===
                                          _vm.PROJECT_SEGMENTS.PERSONALIZER &&
                                          _vm.initialSetupDoneFunction)
                                          ? _c(
                                              "v-tab",
                                              {
                                                key: "gptDatasets",
                                                attrs: { href: "#gptDatasets" },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$vuetify.lang.t(
                                                        "$vuetify.gpt_profile.maira_datasets"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _vm.icons
                                                  ? _c("v-icon", [
                                                      _vm._v("mdi-phone"),
                                                    ])
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        (_vm.selectedProject.segment.name ===
                                          _vm.PROJECT_SEGMENTS.Maira &&
                                          _vm.isGptInitialSetupCompleted) ||
                                        (_vm.selectedProject.segment.name ===
                                          _vm.PROJECT_SEGMENTS.PERSONALIZER &&
                                          _vm.initialSetupDoneFunction)
                                          ? _c(
                                              "v-tab",
                                              {
                                                key: "gptProfiles",
                                                attrs: { href: "#gptProfiles" },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$vuetify.lang.t(
                                                        "$vuetify.profile_menu_txt"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _vm.icons
                                                  ? _c("v-icon", [
                                                      _vm._v("mdi-phone"),
                                                    ])
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        (_vm.selectedProject.segment.name ===
                                          _vm.PROJECT_SEGMENTS.Maira &&
                                          _vm.isGptInitialSetupCompleted) ||
                                        (_vm.selectedProject.segment.name ===
                                          _vm.PROJECT_SEGMENTS.PERSONALIZER &&
                                          _vm.initialSetupDoneFunction)
                                          ? _c(
                                              "v-tab",
                                              {
                                                key: "gptSetting",
                                                attrs: { href: "#gptSetting" },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$vuetify.lang.t(
                                                        "$vuetify.gpt_profile.maira_settings"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _vm.icons
                                                  ? _c("v-icon", [
                                                      _vm._v("mdi-phone"),
                                                    ])
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "v-tab-item",
                                          {
                                            key: "details",
                                            attrs: { value: "details" },
                                          },
                                          [_c("Details")],
                                          1
                                        ),
                                        _c(
                                          "v-tab-item",
                                          {
                                            key: "settings",
                                            attrs: { value: "settings" },
                                          },
                                          [_c("Settings")],
                                          1
                                        ),
                                        _c(
                                          "v-tab-item",
                                          {
                                            key: "insights",
                                            attrs: { value: "insights" },
                                          },
                                          [_c("Insights")],
                                          1
                                        ),
                                        _c(
                                          "v-tab-item",
                                          {
                                            key: "gptSetupTab",
                                            attrs: { value: "gptSetupTab" },
                                          },
                                          [_c("GptSetup")],
                                          1
                                        ),
                                        _c(
                                          "v-tab-item",
                                          {
                                            key: "gptDatasets",
                                            attrs: { value: "gptDatasets" },
                                          },
                                          [_c("GptDatasets")],
                                          1
                                        ),
                                        _c(
                                          "v-tab-item",
                                          {
                                            key: "gptProfiles",
                                            attrs: { value: "gptProfiles" },
                                          },
                                          [
                                            _c("GptProfile", {
                                              attrs: { tabName: _vm.tab },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-tab-item",
                                          {
                                            key: "gptSetting",
                                            attrs: { value: "gptSetting" },
                                          },
                                          [
                                            _c("GptSetting", {
                                              attrs: { tabName: _vm.tab },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm.selectedProject.segment.name ===
                                          _vm.PROJECT_SEGMENTS.PERSONALIZER &&
                                        !_vm.initialSetupDoneFunction
                                          ? _c(
                                              "v-tab-item",
                                              {
                                                key: "setup",
                                                attrs: { value: "setup" },
                                              },
                                              [
                                                _c("Setup", {
                                                  on: {
                                                    setupCompleted:
                                                      _vm.postSetupFinishAction,
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.selectedProject.segment.name ===
                                        _vm.PROJECT_SEGMENTS.PERSONALIZER
                                          ? _c(
                                              "v-tab-item",
                                              {
                                                key: "dataUpdate",
                                                attrs: { value: "dataUpdate" },
                                              },
                                              [_c("DatasetUpdate")],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.selectedProject.segment.name ===
                                          _vm.PROJECT_SEGMENTS.Maira ||
                                        _vm.selectedProject.segment.name ===
                                          _vm.PROJECT_SEGMENTS.PERSONALIZER
                                          ? _c(
                                              "v-tab-item",
                                              {
                                                key: "playground",
                                                staticClass: "pt-4",
                                                attrs: { value: "playground" },
                                              },
                                              [
                                                _c(
                                                  "v-container",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          _vm.isIframeLoading,
                                                        expression:
                                                          "isIframeLoading",
                                                      },
                                                    ],
                                                  },
                                                  [
                                                    _c(
                                                      "v-row",
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass: "pt-4",
                                                            attrs: {
                                                              cols: "12",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-skeleton-loader",
                                                              {
                                                                attrs: {
                                                                  type: "list-item-three-line",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c("iframe", {
                                                  staticClass:
                                                    "overflow-y-auto overflow-x-hidden",
                                                  attrs: {
                                                    src: _vm.getIframeUrl(),
                                                    frameborder: "0",
                                                    width: "100%",
                                                    height: "950px",
                                                  },
                                                  on: {
                                                    load: function ($event) {
                                                      _vm.isIframeLoading = false
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }