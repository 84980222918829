import getEnv from "@/config/env";

export const CAPTCHA_SITE_KEY = getEnv("VUE_APP_CAPTCHA_SITE_KEY");
export const NUMBER_FORMAT_LOCALE = "en-US";
export const CURRENCY = "USD";
export const GPT_MODELS = [
  "gpt-3.5-turbo-0125",
  "gpt-3.5-turbo-0613",
  "gpt-3.5-turbo-instruct",
  "gpt-3.5-turbo-16k-0613",
  "gpt-3.5-turbo-1106",
  "gpt-4-0613",
  "gpt-4-1106-preview",
  "gpt-4-0125-preview",
  "gpt-4-turbo-2024-04-09",
  "gpt-4o-2024-05-13",
  "gpt-4-vision-preview",
  "gpt-4o-mini",
  "gpt-4o-mini-2024-07-18",
  "gpt-4o-2024-08-06",
];
export const IMAGE_KEYS = ['pictures', 'large_pictures', 'small_pictures'];
export const TEXT_KEYS = ['description', 'description_html', 'guide', 'short_description'];

export const MODEL_CONTEXT_WINDOW = {
  "gpt-3.5-turbo-0125": 16385,
  "gpt-3.5-turbo": 16385,
  "gpt-3.5-turbo-1106": 16385,
  "gpt-3.5-turbo-0613": 4096,
  "gpt-3.5-turbo-instruct": 4096,
  "gpt-3.5-turbo-16k-0613": 16385,
  "gpt-4-0613": 8192,
  "gpt-4-1106-preview": 128000,
  "gpt-4-0125-preview": 128000,
  "gpt-4-turbo-2024-04-09": 128000,
  "gpt-4o-2024-05-13": 128000,
  "gpt-4-vision-preview": 128000,
  "gpt-4o-mini": 128000,
  "gpt-4o-mini-2024-07-18": 128000,
  "gpt-4o-2024-08-06": 128000,
}
export const VISION_MODELS = {
  VISION_PREVIEW: "gpt-4-vision-preview",
  VISION_4O: "gpt-4o-2024-05-13",
  VISION_TURBO: "gpt-4-turbo-2024-04-09",
  VISION_MINI: "gpt-4o-mini",
  VISION_MINI_2024: "gpt-4o-mini-2024-07-18",
  VISION_4O_AUG: "gpt-4o-2024-08-06"
};
export const JSON_MODE_MODELS = {
  JSON_4O_MAY: "gpt-4o-2024-05-13",
  JSON_TURBO: "gpt-4-turbo-2024-04-09",
  JSON_35_TURBO_0125: "gpt-3.5-turbo-0125",
  JSON_35_TURBO_1106: "gpt-3.5-turbo-1106",
  JSON_4O_MINI: "gpt-4o-mini",
  JSON_4O_MINI_2024: "gpt-4o-mini-2024-07-18",
  JSON_4O_AUG: "gpt-4o-2024-08-06"
};
export const SEGMENTS = {
  Maira: 'Maira',
  PERSONALIZER: 'Personalizer',
  CV: 'CV'
};

export const SEGMENTS_TAB_STORAGE_KEYS = {Maira : "savedMairaTab", PERSONALIZER : "savedPersonalizerTab",CV: "savedCVTab" }

export const GPT_SETUP_STEPS = ["gpt_profile", "dataset", "training"];

export const MAIRA_TABS = []
export const PERSONALIZER_TABS = ["dataUpdate"]

export const COMMON_TABS = ["settings", "insights", "details", "gptDatasets", "gptProfiles", "gptSetting"]
export const PROMPT_TEMPLATE_SEPARATORS_TOKEN_COUNT = 100

export const HISTORY_TYPES = {
  CONVERSATION: "conversation",
}

export const GPT_PROFILE_INIT_SYSTEM = "You are an expert on COMPANY_NAME/PERSONA/TOPIC. BRIEF INTRODUCTION ABOUT THE COMPANY/PERSONA/TOPIC.";

export const GPT_PROFILE_INIT_DATA = {
  name: "",
  intro: "",
  system: GPT_PROFILE_INIT_SYSTEM,
  model: "",
  modelList: GPT_MODELS,
  temperature: 0,
  top_p: 1,
  frequency_penalty: 0,
  presence_penalty: 0,
  stop: [],
  search_max_token: 2500,
  completion_token: 2000,
  vision_settings: {
    resolution: "low",
    is_image_context_enabled: true,
  },
  id: undefined,
  dataset_tags: {
    includes: [],
    excludes: [],
  },
  chat_history_length: 3,
  is_personalizer_only: false,
  is_auto_evaluation: false,
};
