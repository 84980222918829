var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        { attrs: { "fill-height": "", fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pa-0", attrs: { xs: "12", md: "12" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "mx-auto px-2", attrs: { outlined: "" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { md: "6" } }, [
                            _c(
                              "div",
                              { staticClass: "d-flex align-center" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "primary--text font-weight-bold",
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "pa-0 ma-0",
                                        attrs: { text: "" },
                                        on: { click: _vm.redirectProjectPage },
                                      },
                                      [
                                        _c("v-icon", [
                                          _vm._v(" mdi-arrow-left "),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass:
                                      "primary--text font-weight-bold pa-0 ma-0",
                                    attrs: { text: "" },
                                    on: { click: _vm.redirectProjectPage },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$vuetify.lang.t(
                                            "$vuetify.gpt_dataset.dataset_header_txt"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mx-1",
                                    attrs: { color: "primary" },
                                  },
                                  [_vm._v("mdi-chevron-right")]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass:
                                      "primary--text font-weight-bold pa-0 ma-0",
                                    attrs: { text: "" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$vuetify.lang.t(
                                            "$vuetify.gpt_dataset.dataset_document_header_txt"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("v-col", { attrs: { md: "6" } }, [
                            _c(
                              "div",
                              { staticClass: "d-flex float-right" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "text-capitalize",
                                    attrs: { color: "primary", dark: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.createNewDocumentClick()
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "hidden-sm-and-down" },
                                      [
                                        _c("v-icon", { attrs: { left: "" } }, [
                                          _vm._v("mdi-plus-circle-outline"),
                                        ]),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$vuetify.lang.t(
                                                "$vuetify.gpt_document.create_document"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "hidden-md-and-up" },
                                      [
                                        _c("v-icon", [
                                          _vm._v("mdi-plus-circle-outline"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "text-capitalize ml-4",
                                    attrs: { color: "primary", dark: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleCSVUpload()
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "hidden-sm-and-down" },
                                      [
                                        _c("v-icon", { attrs: { left: "" } }, [
                                          _vm._v("mdi-cloud-upload-outline"),
                                        ]),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$vuetify.lang.t(
                                                "$vuetify.gpt_document.upload_csv"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "hidden-md-and-up" },
                                      [
                                        _c("v-icon", [
                                          _vm._v("mdi-plus-circle-outline"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", xs: "12", md: "3" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "append-icon": "mdi-magnify",
                                  label: _vm.$vuetify.lang.t(
                                    "$vuetify.search_btn_txt"
                                  ),
                                  outlined: "",
                                  dense: "",
                                  "single-line": "",
                                  "hide-details": "",
                                },
                                on: { change: _vm.handleQueryOnDocuments },
                                model: {
                                  value: _vm.search_text,
                                  callback: function ($$v) {
                                    _vm.search_text = $$v
                                  },
                                  expression: "search_text",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", xs: "12", md: "3" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.document_status_states,
                                  "item-text": "state",
                                  "item-value": "value",
                                  "persistent-hint": "",
                                  "hide-details": "auto",
                                  outlined: "",
                                  dense: "",
                                  label: _vm.$vuetify.lang.t(
                                    "$vuetify.gpt_dataset.active_status"
                                  ),
                                },
                                on: { change: _vm.handleQueryOnDocuments },
                                model: {
                                  value: _vm.selected_document_state,
                                  callback: function ($$v) {
                                    _vm.selected_document_state = $$v
                                  },
                                  expression: "selected_document_state",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", xs: "12", md: "3" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.training_status_states,
                                  "item-text": "state",
                                  "item-value": "value",
                                  "persistent-hint": "",
                                  "hide-details": "auto",
                                  outlined: "",
                                  dense: "",
                                  label: _vm.$vuetify.lang.t(
                                    "$vuetify.gpt_dataset.train_status"
                                  ),
                                },
                                on: { change: _vm.handleQueryOnDocuments },
                                model: {
                                  value: _vm.selected_training_state,
                                  callback: function ($$v) {
                                    _vm.selected_training_state = $$v
                                  },
                                  expression: "selected_training_state",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "d-flex justify-end",
                              attrs: { cols: "12", xs: "12", md: "3" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "text-capitalize font-weight-bold",
                                  attrs: { color: "error" },
                                  on: {
                                    click: function ($event) {
                                      _vm.delete_alert_message = _vm
                                        .selectedDocuments.length
                                        ? _vm.$vuetify.lang.t(
                                            "$vuetify.gpt_dataset.confirm_alert_msg_txt_3"
                                          )
                                        : _vm.$vuetify.lang.t(
                                            "$vuetify.gpt_dataset.confirm_alert_msg_txt_2"
                                          )
                                      _vm.deleteDialog = true
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$vuetify.lang.t(
                                          "$vuetify.tooltip.delete"
                                        )
                                      ) +
                                      " (" +
                                      _vm._s(
                                        _vm.selectedDocuments.length || "ALL"
                                      ) +
                                      ") "
                                  ),
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "ml-2",
                                      attrs: { right: "" },
                                    },
                                    [_vm._v("delete")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _vm.isLoading
                        ? _c("Loader", { staticClass: "mr-2 mt-6" })
                        : _c("v-data-table", {
                            attrs: {
                              headers: _vm.headers,
                              items: _vm.gptDocuments,
                              search: _vm.search,
                              page: _vm.page,
                              "custom-sort": _vm.handleSort,
                              "must-sort": true,
                              "sort-by": _vm.sortBy,
                              "sort-desc": _vm.sortDesc,
                              "hide-default-footer": "",
                              "show-select": "",
                              "items-per-page": _vm.itemsPerPage,
                            },
                            on: {
                              "update:page": function ($event) {
                                _vm.page = $event
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: `item.id`,
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on, attrs }) {
                                                  return [
                                                    _c(
                                                      "span",
                                                      _vm._g(
                                                        _vm._b(
                                                          {},
                                                          "span",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.id.split(
                                                              "-"
                                                            )[0]
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [_c("span", [_vm._v(_vm._s(item.id))])]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: `item.data.name`,
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "primary--text text--darken-3",
                                              attrs: { text: "" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.detailsModal(item)
                                                },
                                              },
                                            },
                                            [_vm._v(_vm._s(item.data.name))]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: `item.created_at`,
                                  fn: function ({ item }) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formatDateTime(item.created_at)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: `item.status`,
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-chip",
                                        {
                                          staticClass: "text-capitalize",
                                          attrs: {
                                            color: "primary",
                                            "text-color": "white",
                                            small: "",
                                          },
                                        },
                                        [_vm._v(_vm._s(item.status))]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: `item.actions`,
                                  fn: function ({ item }) {
                                    return [
                                      _c("ButtonGroup", {
                                        staticClass: "d-flex justify-center",
                                        attrs: {
                                          groupType: _vm.ACTION_BUTTON_GROUP,
                                          addButton: false,
                                          startButton: false,
                                          stopButton: false,
                                          disabledDeleteButton:
                                            _vm.disable_crud_operation,
                                          disabledEditButton:
                                            _vm.disable_crud_operation,
                                        },
                                        on: {
                                          onDetailsClick: function ($event) {
                                            return _vm.detailsModal(item)
                                          },
                                          onEditClick: function ($event) {
                                            return _vm.updateItem(item)
                                          },
                                          onDeleteClick: function ($event) {
                                            return _vm.deleteItem(item)
                                          },
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: _vm.selectedDocuments,
                              callback: function ($$v) {
                                _vm.selectedDocuments = $$v
                              },
                              expression: "selectedDocuments",
                            },
                          }),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0",
                              attrs: { cols: "12", md: "12" },
                            },
                            [_c("v-divider")],
                            1
                          ),
                          _c("v-col", {
                            attrs: { cols: "12", xs: "12", sm: "3", md: "3" },
                          }),
                          _c(
                            "v-col",
                            {
                              attrs: { cols: "12", xs: "12", sm: "6", md: "6" },
                            },
                            [
                              _c("v-pagination", {
                                staticClass: "text-center",
                                attrs: { length: _vm.pageCount },
                                model: {
                                  value: _vm.page,
                                  callback: function ($$v) {
                                    _vm.page = $$v
                                  },
                                  expression: "page",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "12",
                                "offset-sm": "1",
                                "offset-md": "1",
                                xs: "3",
                                sm: "2",
                                md: "2",
                              },
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.rows,
                                  "hide-details": "auto",
                                  outlined: "",
                                  dense: "",
                                },
                                model: {
                                  value: _vm.itemsPerPage,
                                  callback: function ($$v) {
                                    _vm.itemsPerPage = $$v
                                  },
                                  expression: "itemsPerPage",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.detailsDialog
        ? [
            _c(
              "v-dialog",
              {
                attrs: {
                  "max-width": "800px",
                  persistent: "",
                  scrollable: "",
                  "hide-overlay": "",
                  transition: "dialog-bottom-transition",
                  "content-class": "rounded-lg",
                  elevation: "24",
                  rounded: "",
                },
                model: {
                  value: _vm.detailsDialog,
                  callback: function ($$v) {
                    _vm.detailsDialog = $$v
                  },
                  expression: "detailsDialog",
                },
              },
              [
                !_vm.detailsLoader
                  ? _c(
                      "v-card",
                      {
                        staticClass: "rounded-lg",
                        attrs: { elevation: "24", rounded: "" },
                      },
                      [
                        _c("v-card-title", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$vuetify.lang.t("$vuetify.tooltip.document")
                              ) +
                              " " +
                              _vm._s(
                                _vm.$vuetify.lang.t(
                                  "$vuetify.details_header_txt"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c(
                          "v-card-text",
                          [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.$vuetify.lang.t("$vuetify.id_header_txt")
                                ) +
                                  ": " +
                                  _vm._s(_vm.document_details.id)
                              ),
                            ]),
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.$vuetify.lang.t(
                                    "$vuetify.dashboard.active"
                                  )
                                ) +
                                  " " +
                                  _vm._s(
                                    _vm.$vuetify.lang.t(
                                      "$vuetify.status_header_txt"
                                    )
                                  ) +
                                  ": "
                              ),
                              _c("span", { staticClass: "text-capitalize" }, [
                                _vm._v(
                                  _vm._s(_vm.document_details.active_status)
                                ),
                              ]),
                            ]),
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.$vuetify.lang.t("$vuetify.created_at_txt")
                                ) +
                                  ": " +
                                  _vm._s(
                                    _vm.formatDateTime(
                                      _vm.document_details.created_at
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.$vuetify.lang.t(
                                    "$vuetify.status_header_txt"
                                  )
                                ) + ": "
                              ),
                              _c("span", { staticClass: "text-capitalize" }, [
                                _vm._v(_vm._s(_vm.document_details.status)),
                              ]),
                            ]),
                            _c(
                              "v-container",
                              [
                                _c(
                                  "v-row",
                                  { staticClass: "pa-0 ma-0" },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "py-1 my-0",
                                        attrs: { cols: "6" },
                                      },
                                      [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$vuetify.lang.t(
                                                "$vuetify.gpt_document.document_key"
                                              )
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "py-1 my-0",
                                        attrs: { cols: "6" },
                                      },
                                      [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$vuetify.lang.t(
                                                "$vuetify.gpt_document.document_value"
                                              )
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._l(
                                  _vm.document_details.data,
                                  function (value, key) {
                                    return _c(
                                      "v-row",
                                      { key: key, staticClass: "pa-0 ma-0" },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "py-1 my-0",
                                            attrs: { cols: "6" },
                                          },
                                          [_vm._v(_vm._s(key))]
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "py-1 my-0",
                                            attrs: { cols: "6" },
                                          },
                                          [_vm._v(_vm._s(value))]
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-card-actions",
                          { staticClass: "d-flex justify-end" },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: { text: "", color: "error" },
                                on: {
                                  click: function ($event) {
                                    _vm.detailsDialog = false
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$vuetify.lang.t(
                                        "$vuetify.close_btn_txt"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _c("Loader"),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm.createNewDocumentDialog
        ? [
            _c(
              "v-dialog",
              {
                attrs: {
                  "max-width": "800px",
                  persistent: "",
                  scrollable: "",
                  "hide-overlay": "",
                  transition: "dialog-bottom-transition",
                  "content-class": "rounded-lg",
                  elevation: "24",
                  rounded: "",
                },
                model: {
                  value: _vm.createNewDocumentDialog,
                  callback: function ($$v) {
                    _vm.createNewDocumentDialog = $$v
                  },
                  expression: "createNewDocumentDialog",
                },
              },
              [
                _c(
                  "v-card",
                  {
                    staticClass: "rounded-lg",
                    attrs: { elevation: "24", rounded: "" },
                  },
                  [
                    _c("v-card-title", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.toBeUpdatedDocumentId !== ""
                              ? _vm.$vuetify.lang.t(
                                  "$vuetify.gpt_document.update_document"
                                )
                              : _vm.$vuetify.lang.t(
                                  "$vuetify.create_action_txt"
                                ) +
                                  " " +
                                  _vm.$vuetify.lang.t(
                                    "$vuetify.gpt_document.create_document"
                                  )
                          ) +
                          " "
                      ),
                    ]),
                    _c(
                      "v-card-text",
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "pa-0",
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "text-center font-weight-bold pa-0 ma-0",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$vuetify.lang.t(
                                            "$vuetify.gpt_document.document_key"
                                          )
                                        )
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "pa-0",
                                attrs: { cols: "12", md: "8" },
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "text-center font-weight-bold pa-0 ma-0",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$vuetify.lang.t(
                                            "$vuetify.gpt_document.document_value"
                                          )
                                        )
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c("hr", { staticClass: "my-2" }),
                        _c(
                          "v-form",
                          { ref: "document_form" },
                          _vm._l(
                            _vm.newDocuments,
                            function (newDocument, index) {
                              return _c(
                                "v-row",
                                { key: index, staticClass: "pa-0 ma-0" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0 my-0",
                                      attrs: { cols: "12", md: "4" },
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          outlined: "",
                                          dense: "",
                                          rules: [
                                            (v) =>
                                              !_vm.newDocuments.some(
                                                (doc, i) =>
                                                  doc.key === v && i !== index
                                              ) ||
                                              _vm.$vuetify.lang.t(
                                                "$vuetify.common.duplicate_error_txt"
                                              ),
                                            (v) =>
                                              !!v ||
                                              _vm.$vuetify.lang.t(
                                                "$vuetify.common.field_required_error_txt"
                                              ),
                                          ],
                                        },
                                        model: {
                                          value: newDocument.key,
                                          callback: function ($$v) {
                                            _vm.$set(newDocument, "key", $$v)
                                          },
                                          expression: "newDocument.key",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0 my-0",
                                      attrs: { cols: "12", md: "8" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex" },
                                        [
                                          _c("v-textarea", {
                                            attrs: {
                                              outlined: "",
                                              dense: "",
                                              rows: "2",
                                              rules: [
                                                (v) =>
                                                  !!v ||
                                                  _vm.$vuetify.lang.t(
                                                    "$vuetify.common.field_required_error_txt"
                                                  ),
                                              ],
                                            },
                                            model: {
                                              value: newDocument.value,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  newDocument,
                                                  "value",
                                                  $$v
                                                )
                                              },
                                              expression: "newDocument.value",
                                            },
                                          }),
                                          _vm.newDocuments.length > 1
                                            ? _c(
                                                "v-btn",
                                                {
                                                  attrs: { icon: "" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.removeDocument(
                                                        index
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { color: "red" } },
                                                    [_vm._v("mdi-minus")]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                          1
                        ),
                        _c(
                          "v-row",
                          { staticClass: "pa-0" },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "d-flex justify-end",
                                attrs: { cols: "12" },
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { outlined: "", color: "primary" },
                                    on: { click: _vm.addMoreData },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$vuetify.lang.t(
                                            "$vuetify.project.add_more"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-card-actions",
                      { staticClass: "d-flex justify-end" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { text: "", color: "error" },
                            on: { click: _vm.closeDocumentModel },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$vuetify.lang.t("$vuetify.close_btn_txt")
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _vm.toBeUpdatedDocumentId !== ""
                          ? _c(
                              "v-btn",
                              {
                                attrs: { color: "primary" },
                                on: { click: _vm.updateDocumentBtn },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$vuetify.lang.t(
                                        "$vuetify.project.update"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _c(
                              "v-btn",
                              {
                                attrs: { color: "primary" },
                                on: { click: _vm.createNewDocument },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$vuetify.lang.t(
                                        "$vuetify.create_action_txt"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm.uploadCSVDialog
        ? [
            _c(
              "v-dialog",
              {
                attrs: {
                  "max-width": "800px",
                  persistent: "",
                  scrollable: "",
                  "hide-overlay": "",
                  transition: "dialog-bottom-transition",
                  "content-class": "rounded-lg",
                  elevation: "24",
                  rounded: "",
                },
                model: {
                  value: _vm.uploadCSVDialog,
                  callback: function ($$v) {
                    _vm.uploadCSVDialog = $$v
                  },
                  expression: "uploadCSVDialog",
                },
              },
              [
                _c(
                  "v-card",
                  {
                    staticClass: "rounded-lg",
                    attrs: { elevation: "24", rounded: "" },
                  },
                  [
                    _c("v-card-title", { staticClass: "ml-4" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$vuetify.lang.t(
                              "$vuetify.gpt_dataset.document_upload_header_txt"
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c(
                      "v-card-text",
                      [
                        _c(
                          "v-form",
                          { ref: "dataset_form" },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "12" } },
                              [
                                _c("v-file-input", {
                                  attrs: {
                                    outlined: "",
                                    accept:
                                      ".json, .csv, application/json, text/csv",
                                    label: _vm.$vuetify.lang.t(
                                      "$vuetify.gpt_dataset.dataset_file"
                                    ),
                                    rules: _vm.fileInputRules,
                                  },
                                  on: { change: _vm.handleFileChange },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          { staticClass: "pa-0" },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "d-flex justify-end pr-6",
                                attrs: { cols: "12" },
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { outlined: "", color: "primary" },
                                    on: { click: _vm.uploadDataset },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$vuetify.lang.t(
                                            "$vuetify.upload_btn_txt"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-card-actions",
                      { staticClass: "d-flex justify-end pr-6" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { text: "", color: "error" },
                            on: {
                              click: function ($event) {
                                _vm.uploadCSVDialog = false
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$vuetify.lang.t("$vuetify.close_btn_txt")
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm.is_task_running
        ? [
            _c(
              "v-dialog",
              {
                attrs: {
                  "max-width": "400px",
                  persistent: "",
                  transition: "dialog-bottom-transition",
                  "content-class": "rounded-lg",
                  elevation: "24",
                  rounded: "",
                },
                model: {
                  value: _vm.is_task_running,
                  callback: function ($$v) {
                    _vm.is_task_running = $$v
                  },
                  expression: "is_task_running",
                },
              },
              [
                _c(
                  "v-card",
                  {
                    staticClass: "rounded-lg",
                    attrs: { elevation: "24", rounded: "" },
                  },
                  [
                    _c("v-card-title", [
                      _c("div", [
                        _c(
                          "span",
                          { staticClass: "headline text-capitalize" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.uploadingCSV
                                    ? _vm.documentUploadHeader
                                    : _vm.$vuetify.lang.t(
                                        "$vuetify.gpt_document.delete_document_progress_header_txt"
                                      )
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _c(
                      "v-card-text",
                      [
                        _c(
                          "v-list-item",
                          [
                            _c(
                              "v-list-item-icon",
                              [
                                _c("v-progress-circular", {
                                  attrs: {
                                    indeterminate: "",
                                    color: "primary",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-list-item-content",
                              [
                                _c("v-list-item-title", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.uploadingCSV
                                          ? _vm.uploadCSVMessage
                                          : _vm.$vuetify.lang.t(
                                              "$vuetify.gpt_document.delete_document_progress_txt"
                                            )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-card-actions",
                      { staticClass: "d-flex justify-end" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { text: "", color: "error" },
                            on: {
                              click: function ($event) {
                                _vm.is_task_running = false
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$vuetify.lang.t("$vuetify.close_btn_txt")
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _c("DialogModal", {
        attrs: {
          title: _vm.$vuetify.lang.t("$vuetify.confirm_alert_title_txt"),
          message: _vm.delete_alert_message,
          confirmButtonText: _vm.$vuetify.lang.t("$vuetify.confirm_btn_txt"),
          cancelButtonText: _vm.$vuetify.lang.t("$vuetify.cancel_btn_txt"),
        },
        on: {
          onConfirmation: function ($event) {
            return _vm.deleteDocuments()
          },
          onCancel: function ($event) {
            _vm.deleteDialog = false
          },
        },
        model: {
          value: _vm.deleteDialog,
          callback: function ($$v) {
            _vm.deleteDialog = $$v
          },
          expression: "deleteDialog",
        },
      }),
      _c("DialogModal", {
        attrs: {
          title: _vm.$vuetify.lang.t("$vuetify.error_title_txt"),
          message: _vm.alertMessage,
          confirmButtonText: _vm.$vuetify.lang.t("$vuetify.ok_btn_txt"),
          cancelButton: false,
        },
        on: {
          onConfirmation: function ($event) {
            _vm.alertDialog = false
          },
        },
        model: {
          value: _vm.alertDialog,
          callback: function ($$v) {
            _vm.alertDialog = $$v
          },
          expression: "alertDialog",
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }