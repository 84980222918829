var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "mx-auto",
      attrs: { rounded: "", outlined: "", height: "100%" },
    },
    [
      _c(
        "v-card-subtitle",
        {
          staticClass:
            "font-weight-bold d-flex justify-space-between align-center",
        },
        [
          _c(
            "div",
            { staticClass: "d-flex align-center" },
            [
              _vm._v(
                " " +
                  _vm._s(
                    this.$vuetify.lang.t("$vuetify.insights.repeated_users")
                  ) +
                  " "
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "v-icon",
                            _vm._g(
                              _vm._b(
                                { staticClass: "ml-1", attrs: { small: "" } },
                                "v-icon",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_vm._v(" mdi-help-circle-outline ")]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        this.$vuetify.lang.t("$vuetify.insights.repeated_users")
                      ) +
                      _vm._s(_vm.repeatedUsers) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "v-btn",
            {
              attrs: {
                small: "",
                color: "primary",
                disabled: _vm.repeatedUsers.length === 0,
              },
              on: {
                click: function ($event) {
                  return _vm.downloadCSV("Repeated Users", _vm.repeatedUsers)
                },
              },
            },
            [
              _c("v-icon", { attrs: { left: "", small: "" } }, [
                _vm._v("mdi-download"),
              ]),
              _vm._v(" CSV "),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-divider"),
      _c("v-data-table", {
        attrs: {
          headers: _vm.userHeaders,
          items: _vm.repeatedUsers,
          "items-per-page": 5,
        },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function ({ item }) {
              return [_c("tr", [_c("td", [_vm._v(_vm._s(item))])])]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }