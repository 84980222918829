var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.dataset
    ? _c("v-skeleton-loader", {
        staticClass: "mr-2 mt-6",
        attrs: { type: "list-item-three-line" },
      })
    : _c(
        "v-form",
        { ref: "dataset_form" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "12" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      type: "text",
                      label: _vm.$vuetify.lang.t(
                        "$vuetify.gpt_dataset.dataset_name"
                      ),
                      rules: [
                        _vm.required(
                          _vm.$vuetify.lang.t(
                            "$vuetify.gpt_dataset.dataset_name"
                          )
                        ),
                      ],
                      outlined: "",
                    },
                    model: {
                      value: _vm.dataset.datasetName,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataset, "datasetName", $$v)
                      },
                      expression: "dataset.datasetName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      type: "text",
                      label: _vm.$vuetify.lang.t(
                        "$vuetify.gpt_dataset.index_column_name"
                      ),
                      rules: [
                        _vm.required(
                          _vm.$vuetify.lang.t(
                            "$vuetify.gpt_dataset.index_column_name"
                          )
                        ),
                      ],
                      outlined: "",
                    },
                    model: {
                      value: _vm.dataset.indexColumnName,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataset, "indexColumnName", $$v)
                      },
                      expression: "dataset.indexColumnName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      type: "text",
                      label: _vm.$vuetify.lang.t(
                        "$vuetify.gpt_dataset.secondary_index_column"
                      ),
                      outlined: "",
                    },
                    model: {
                      value: _vm.dataset.secondaryIndex,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataset, "secondaryIndex", $$v)
                      },
                      expression: "dataset.secondaryIndex",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      type: "text",
                      label: _vm.$vuetify.lang.t(
                        "$vuetify.gpt_dataset.image_url_column"
                      ),
                      outlined: "",
                    },
                    model: {
                      value: _vm.dataset.imageColumn,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataset, "imageColumn", $$v)
                      },
                      expression: "dataset.imageColumn",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      type: "text",
                      label: _vm.$vuetify.lang.t(
                        "$vuetify.gpt_dataset.description_label_txt"
                      ),
                      outlined: "",
                    },
                    model: {
                      value: _vm.dataset.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataset, "description", $$v)
                      },
                      expression: "dataset.description",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "12" } },
                [
                  _c("v-combobox", {
                    staticClass: "no-dropdown-icon",
                    attrs: {
                      items: _vm.tagChips,
                      label: _vm.$vuetify.lang.t(
                        "$vuetify.gpt_profile.dataset_tags"
                      ),
                      variant: "solo",
                      chips: "",
                      clearable: "",
                      outlined: "",
                      hint: _vm.$vuetify.lang.t(
                        "$vuetify.gpt_profile.chip_hint"
                      ),
                      multiple: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "selection",
                        fn: function ({ attrs, item, selected }) {
                          return [
                            _c(
                              "v-chip",
                              _vm._b(
                                {
                                  attrs: {
                                    "model-value": selected,
                                    closable: "",
                                  },
                                },
                                "v-chip",
                                attrs,
                                false
                              ),
                              [_c("span", [_vm._v(_vm._s(item))]), _vm._v("  ")]
                            ),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.dataset.tags,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataset, "tags", $$v)
                      },
                      expression: "dataset.tags",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "12" } },
                [
                  _c("v-combobox", {
                    staticClass: "no-dropdown-icon",
                    attrs: {
                      items: _vm.filterableFieldsChips,
                      label: _vm.$vuetify.lang.t(
                        "$vuetify.gpt_profile.filterable_fields"
                      ),
                      variant: "solo",
                      chips: "",
                      clearable: "",
                      outlined: "",
                      hint: _vm.$vuetify.lang.t(
                        "$vuetify.gpt_profile.filterable_chip_hint"
                      ),
                      multiple: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "selection",
                        fn: function ({ attrs, item, selected }) {
                          return [
                            _c(
                              "v-chip",
                              _vm._b(
                                {
                                  attrs: {
                                    "model-value": selected,
                                    closable: "",
                                  },
                                },
                                "v-chip",
                                attrs,
                                false
                              ),
                              [_c("span", [_vm._v(_vm._s(item))]), _vm._v("  ")]
                            ),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.dataset.filterableFields,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataset, "filterableFields", $$v)
                      },
                      expression: "dataset.filterableFields",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "12" } },
                [
                  _c("v-file-input", {
                    attrs: {
                      outlined: "",
                      accept: ".json, .csv, application/json, text/csv",
                      label: _vm.$vuetify.lang.t(
                        "$vuetify.gpt_dataset.dataset_file"
                      ),
                      rules: _vm.fileInputRules,
                    },
                    on: { change: _vm.handleFileChange },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c("v-checkbox", {
                    attrs: {
                      label: _vm.$vuetify.lang.t(
                        "$vuetify.gpt_dataset.fill_up_index_if_empty"
                      ),
                    },
                    model: {
                      value: _vm.dataset.fillUpEmptyIndex,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataset, "fillUpEmptyIndex", $$v)
                      },
                      expression: "dataset.fillUpEmptyIndex",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-row"),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }