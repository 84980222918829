var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "50%", persistent: "" },
      model: {
        value: _vm.open,
        callback: function ($$v) {
          _vm.open = $$v
        },
        expression: "open",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v(" " + _vm._s(_vm.title) + " ")]),
          _c("v-divider"),
          !_vm.response
            ? _c(
                "v-card-text",
                { staticClass: "py-4" },
                [
                  _c(
                    "v-form",
                    { ref: "fileGenerationForm" },
                    [
                      _c(
                        "v-row",
                        { staticClass: "justify-start" },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", xs: "12", md: "4" } },
                            [
                              _c(
                                "v-menu",
                                {
                                  ref: "fileDateRangeModal",
                                  attrs: {
                                    "close-on-content-click": false,
                                    "return-value": _vm.fileDateRange,
                                    transition: "scale-transition",
                                    "offset-y": "",
                                    "min-width": "auto",
                                  },
                                  on: {
                                    "update:returnValue": function ($event) {
                                      _vm.fileDateRange = $event
                                    },
                                    "update:return-value": function ($event) {
                                      _vm.fileDateRange = $event
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-text-field",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      value: _vm.dateRangeText,
                                                      placeholder:
                                                        _vm.$vuetify.lang.t(
                                                          "$vuetify.daterange_placeholder_txt"
                                                        ),
                                                      "prepend-icon":
                                                        "mdi-calendar",
                                                      readonly: "",
                                                      outlined: "",
                                                      dense: "",
                                                      "hide-details": "auto",
                                                      rules: [
                                                        (v) =>
                                                          (v.split(" ~ ")
                                                            .length === 2 &&
                                                            new Date(
                                                              v.split(" ~ ")[0]
                                                            ) <=
                                                              new Date(
                                                                v.split(
                                                                  " ~ "
                                                                )[1]
                                                              )) ||
                                                          _vm.$vuetify.lang.t(
                                                            "$vuetify.logs_file_generation.date_range_validation"
                                                          ),
                                                      ],
                                                    },
                                                  },
                                                  "v-text-field",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              )
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    599277132
                                  ),
                                  model: {
                                    value: _vm.fileDateRangeModal,
                                    callback: function ($$v) {
                                      _vm.fileDateRangeModal = $$v
                                    },
                                    expression: "fileDateRangeModal",
                                  },
                                },
                                [
                                  _c(
                                    "v-date-picker",
                                    {
                                      attrs: {
                                        color: "primary",
                                        "no-title": "",
                                        scrollable: "",
                                        range: "",
                                        max: _vm.dateOfToday(),
                                      },
                                      model: {
                                        value: _vm.fileDateRange,
                                        callback: function ($$v) {
                                          _vm.fileDateRange = $$v
                                        },
                                        expression: "fileDateRange",
                                      },
                                    },
                                    [
                                      _c("v-spacer"),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { text: "", color: "error" },
                                          on: {
                                            click: function ($event) {
                                              _vm.fileDateRangeModal = false
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$vuetify.lang.t(
                                                  "$vuetify.cancel_btn_txt"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { text: "", color: "primary" },
                                          on: { click: _vm.setDates },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$vuetify.lang.t(
                                                  "$vuetify.OK_btn_txt"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c("v-card-text", { staticClass: "py-4" }, [
                _vm.response.task_id
                  ? _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$vuetify.lang.t(
                              "$vuetify.logs_file_generation.file_not_exists"
                            )
                          ) +
                          " "
                      ),
                    ])
                  : _c("div", [
                      _c("p", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$vuetify.lang.t(
                                "$vuetify.logs_file_generation.file_already_exists"
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _c("p", [
                        _c("b", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.response.file_url,
                                target: "_blank",
                                rel: "noopener noreferrer",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$vuetify.lang.t(
                                      "$vuetify.logs_file_generation.download_file_url_text"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _c("p", [
                        _c("b", [
                          _vm._v(
                            _vm._s(
                              _vm.$vuetify.lang.t(
                                "$vuetify.logs_file_generation.expiration"
                              )
                            ) + ": "
                          ),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.formatDate(_vm.response.expiration))
                          ),
                        ]),
                      ]),
                    ]),
              ]),
          _c("v-divider"),
          !_vm.response
            ? _c(
                "v-card-actions",
                { staticClass: "justify-end" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { outlined: "", color: "error" },
                      on: { click: _vm.closeModal },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$vuetify.lang.t("$vuetify.cancel_btn_txt"))
                      ),
                    ]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        loading: _vm.fileGenerationLoading,
                      },
                      on: { click: _vm.downloadRequest },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$vuetify.lang.t("$vuetify.generate_btn_txt"))
                      ),
                    ]
                  ),
                ],
                1
              )
            : _c(
                "v-card-actions",
                { staticClass: "justify-end" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { outlined: "", color: "error" },
                      on: { click: _vm.closeResponse },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$vuetify.lang.t("$vuetify.close_btn_txt"))
                      ),
                    ]
                  ),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }